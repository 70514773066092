import {
  Box,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import GetInTouchImg from "../../assets/FormImage/Default.png";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { ApiUrl } from "../siteconfig/config";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import GenericButtonSchedule from "../genericButtonSchedule/GenericButtonSchedule";

interface FormObj {
  name: string;
  email: string;
  mobile: string;
  applicationType: string;
  description: string;
}

interface State extends SnackbarOrigin {
  open: boolean;
}

const useStyles = makeStyles((theme) => ({
  select: {
    color: "#575e60d6 !important",
    borderRadius: "24px !important",
    backgroundColor: "rgba(14, 12, 23, 0.85) !important",
    backdropFilter: "blur(2px) !important",
    position : 'relative',
    zIndex : '1',
  },
  menuItem: {
    color: "#FFF !important",
    fontFamily: "Inter !important",
    fontSize: "16px !important",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "32px !important",
    "&:hover": {
      borderRadius: "20px",
      background: "#26213E !important",
      transition: "background-color 0.6s ease !important",
      display: "flex",
      alignItems: "center",
      gap: "10px !important",
      flexShrink: 0,
    },
  },
  menuPaper: {
    borderRadius: "24px !important",
    border: "1px solid #646464",
    background: "rgba(14, 12, 23, 0.85) !important",
    backdropFilter: "blur(2px)",

    display: "flex !important",
    height: "334px !important",
    padding: "4px 10px !important",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px !important",
    flexShrink: 0,
    position : 'absolute',
    zIndex : '2',
    transition: "0.6s ease !important",
  },
  icon: {
    fill: "white !important",
  },
}));





const GetInTouch = () => {
  const classes = useStyles();
  const [formData, setFormData] = useState<FormObj>({
    name: "",
    email: "",
    mobile: "",
    applicationType: "",
    description: "",
  });
  const [state, setState] = React.useState<State>({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
  const [msgInfo, setMsgInfo] = useState<
    "success" | "info" | "warning" | "error"
  >("error");
  const [message, setMessage] = useState<string>("");

  const [choices, setChoices] = useState<any>([]);

  useEffect(() => {
    const getData = async () => {
      const responseData = await fetch(
        `${ApiUrl}/api/contact-form-application-types?populate=*`
      );
      const apiData = await responseData.json();
      //console.log("apiData", apiData?.data);
      setChoices(apiData?.data);
    };
    getData();
  }, []);

  const contactForm = (e: any) => {
    if (e.target.id === undefined) {
      setFormData({ ...formData, ["applicationType"]: e.target.value });
      return;
    }
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  async function postData(url = "", data = {}) {
    try {
      const response = await axios.post(url, data);
      return response.data;
    } catch (error) {
      console.error("Error occurred:", error);
      setMsgInfo("error");
      setMessage("Something Went Wrong");
      handleClick({ vertical: "top", horizontal: "right" });
    }
  }

  // send message trigger
  const sendMsgtrigger = () => {
    const emailRegex = /\S+@\S+\.\S+/;
    const mobileRegex = /^[6-9]\d{9}$/;
    const checkVal = Object.values(formData).every((item: any) => item !== "");
    if (checkVal) {
      if (!emailRegex.test(formData?.email)) {
        setMsgInfo("error");
        setMessage("Please Enter a Vaild Email");
        handleClick({ vertical: "top", horizontal: "right" });
      } else if (!mobileRegex.test(formData?.mobile)) {
        setMsgInfo("error");
        setMessage("Please Enter a Vaild mobile number");
        handleClick({ vertical: "top", horizontal: "right" });
      } else {
        postData(`${ApiUrl}/api/pricing-contact-forms`, {
          data: {
            name: formData?.name,
            email: formData?.email,
            description: formData?.description,
            phone_no: formData?.mobile,
            application_type: formData?.applicationType,
          },
        }).then((data) => {
          if (data) {
            setMsgInfo("success");
            setMessage("Message Send");
            handleClick({ vertical: "top", horizontal: "right" });
            setFormData({
              name: "",
              email: "",
              mobile: "",
              applicationType: "",
              description: "",
            });
          }
        });
      }
    } else {
      setMsgInfo("error");
      setMessage("Please Fill All Fields");
      handleClick({ vertical: "top", horizontal: "right" });
    }
  };

  const handleClick = (newState: SnackbarOrigin) => {
    setState({ ...newState, open: true });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props,ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        key={vertical + horizontal}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert
          onClose={handleClose}
          severity={msgInfo}
          sx={{ width: "100%", fontWeight: "600" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Box
        sx={{ padding: { xs: "auto", sm: "auto", md: "80px 130px 0 130px" } }}
      >
        <Box className="formBox">
          <form>
            <Grid container>
              {/* first container  */}
              <Grid container spacing={0}>
                {/* your name field */}
                <Grid xs={12} sm={6} md={6} lg={6}>
                  <TextField
                    id="name"
                    className="outlined-basic"
                    placeholder="Enter your name here"
                    variant="outlined"
                    onChange={(e) => contactForm(e)}
                    value={formData?.name ?? ""}
                    sx={{
                      margin: "15px 0",
                      width: "95%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderRadius: "24px",
                          borderColor: "transparent",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#84989F",
                        fontFamily: "in",
                      },
                    }}
                    InputProps={{
                      sx: {
                        color: "#84989F",
                        borderRadius: "24px",
                        backgroundColor: "rgba(14, 12, 23, 0.85)",
                        backdropFilter: "blur(2px)",
                      },
                    }}
                  />
                </Grid>

                {/* your email */}
                <Grid xs={12} sm={6} md={6} lg={6}>
                  <TextField
                    id="email"
                    className="outlined-basic"
                    onChange={(e) => contactForm(e)}
                    value={formData?.email ?? ""}
                    placeholder="Enter your email address"
                    variant="outlined"
                    sx={{
                      margin: "15px 0",
                      width: "95%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderRadius: "24px",
                          borderColor: "transparent",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#84989F",
                      },
                    }}
                    InputProps={{
                      sx: {
                        color: "#84989F",
                        borderRadius: "24px",
                        backgroundColor: "#0E0C17D9",
                      },
                    }}
                  />
                </Grid>
              </Grid>

              {/* second container */}
              <Grid container spacing={0}>
                {/* your phone number */}
                <Grid xs={12} sm={6} md={6} lg={6}>
                  <TextField
                    id="mobile"
                    className="outlined-basic"
                    placeholder="Enter your mobile number"
                    variant="outlined"
                    onChange={(e) => contactForm(e)}
                    value={formData?.mobile ?? ""}
                    sx={{
                      margin: "15px 0",
                      width: "95%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderRadius: "24px",
                          borderColor: "transparent",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#84989F",
                        fontFamily: "in",
                      },
                    }}
                    InputProps={{
                      sx: {
                        color: "#84989F",
                        borderRadius: "24px",
                        backgroundColor: "rgba(14, 12, 23, 0.85)",
                        backdropFilter: "blur(2px)",
                      },
                    }}
                  />
                </Grid>

                {/* dropdown */}
                <Grid xs={12} sm={6} md={6} lg={6}>
                  <FormControl
                    fullWidth
                    sx={{
                      margin: "15px 0",
                      width: "95%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderRadius: "24px",
                          borderColor: "transparent",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#84989F",
                      },
                    }}
                  >
                    <Select
                      className={classes.select}
                      labelId="applicationType"
                      id="applicationType"
                      variant="outlined"
                      value={
                        formData?.applicationType !== ""
                          ? formData?.applicationType
                          : "Select Application type"
                      }
                      onChange={(e) => contactForm(e)}
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                      }}
                      renderValue={(value) =>
                        value === "" ? "Select Application type" : value
                      }
                      MenuProps={{
                        classes: { paper: classes.menuPaper },
                      }}
                      sx={{
                        color: "#575e60d6 !important",
                        fontWeight: "400 !important",
                        textAlign: "start !important",
                      }}
                    >
                      <MenuItem
                        disabled
                        className={classes.menuItem}
                        sx={{
                           padding: "0px",
                           display : 'flex',
                           justifyContent : 'space-between',
                       }}
                        value=""
                      >
                        <em>Select Application type</em>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="10"
                          viewBox="0 0 17 10"
                          fill="none"
                        >
                          <path
                            d="M1 8.75L8.5 1.25L16 8.75"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </MenuItem>
                      <Divider
                        sx={{
                          background: "#463981",
                          height: "1px",
                          padding: "0px",
                        }}
                      />
                      {choices.map((choice: any) => (
                        <MenuItem
                          className={classes.menuItem}
                          key={choice?.id}
                          value={choice?.attributes?.name}
                        >
                          {choice?.attributes?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              {/* third container */}
              <Grid xs={12} sm={12}>
                <TextField
                  id="description"
                  className="outlined-basic"
                  onChange={(e) => contactForm(e)}
                  value={formData?.description ?? ""}
                  placeholder="Give a description of the problem space here"
                  variant="outlined"
                  rows={4}
                  multiline
                  sx={{
                    margin: "15px 0",
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "24px",
                        borderColor: "transparent",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "#84989F",
                    },
                  }}
                  InputProps={{
                    sx: {
                      color: "#84989F",
                      borderRadius: "24px",
                      backgroundColor: "#0E0C17D9",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </form>
          <Box
           sx={{
             display: "flex",
             justifyContent: "center",
            //  width: { xs: "100%", sm: "100%", md: "400px", lg: "100%" },
            //  textAlign : 'center',
             height: "auto",
             cursor: "pointer",
             }}
             >
           <GenericButtonSchedule clickModal={sendMsgtrigger} text={'get in touch'} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default GetInTouch;


// Effective button before Generic button
{
             /* <Box
              onClick={sendMsgtrigger}
              component="img"
              src={GetInTouchImg}
              sx={{
                width: { xs: "100%", sm: "100%", md: "400px", lg: "100%" },
                height: "auto",
                cursor: "pointer",
              }}
              alt="responsive image"
            /> */
}

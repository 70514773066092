import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography, TextField } from "@mui/material";
import "./style.css"
import Services1 from "../../assets/services/services1.png"
import beAhead from "../../assets/beAhead.png"
import totalEcommerce from "../../assets/services/totalEcommerce.png"
import increaseProfit from "../../assets/services/increaseProfit.png"
import putCustom from "../../assets/services/putCustom.png"
import icon1 from "../../assets/services/icon1.png"
import icon2 from "../../assets/services/icon2.png"
import icon3 from "../../assets/services/icon3.png"
import CheckIcon from "../../assets/CheckIcon.png"
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import GetInTouch from "./GetInTouch";
export default function Services() {
    const [expanded, setExpanded] = useState('panel1');
    const [expanded2, setExpanded2] = useState('panelA');
    const [expanded3, setExpanded3] = useState('panelD');
    const handleChange = (panel: any) => (event: any, isExpanded: any) => {
        setExpanded(isExpanded ? panel : false);
    };
    const handleChangeTab2 = (panel: any) => (event: any, isExpanded: any) => {
        setExpanded2(isExpanded ? panel : false);
    };
    const handleChangeThree = (panel: any) => (event: any, isExpanded: any) => {
        setExpanded3(isExpanded ? panel : false);
    };
    return (
        <Box>
            <Box className="boxPaddingGrid">
                <Grid container sx={{ margin: { xs: "100px 0 0 0px", sm: "100px 0 0 0px", md: "52px 0px", lg: "60px 0px"} }}>
                    <Grid xs={12} sm={6}>
                        <Box>
                            <Box sx={{ padding: { xs: "0px", sm: "0px", md: "25px 38px", lg:"120px 75px" } }}>
                                <Typography className="colorWhite" variant="h3">Our Services,
                                    Your Growth</Typography>
                                <Typography sx={{marginTop:"15px"}} className="colorGray" variant="body1">Don’t wait for our competitors to use AI. Lead the way and set the standard for your industry.</Typography>

                                <div>
                                </div>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid xs={12} sm={6}>
                        <Box className="">
                            <img src={Services1} className="width100" />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container sx={{ margin: { xs: "0px 0px", sm: "0px 0px", md: "60px 0px" } }}>
                    <Grid xs={12} sm={12} md={4}>
                        <Box sx={{padding:{xs:"5px", sm:"5px", md:"10px"}}}>
                            <img src={icon1} style={{marginBottom:"15px"}}  />
                            <Typography variant="h4" sx={{fontSize:{xs:"18px",sm:"18px",md:"24px"}}} className="colorWhite" style={{marginBottom:"5px"}} >Custom AI Solutions</Typography>
                            <Typography className="colorGray" variant="body1">
                            Allow the chatbot to integrate with a wider range of enterprise software, such as CRMs, ERPs, and HR systems.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid xs={12} sm={12} md={4}>
                        <Box sx={{padding:{xs:"5px", sm:"5px", md:"10px"}}}>
                            <img src={icon2} style={{marginBottom:"15px"}}  />
                            <Typography variant="h4" sx={{fontSize:{xs:"18px",sm:"18px",md:"24px"}}} className="colorWhite" style={{marginBottom:"5px"}} >Generative AI</Typography>
                            <Typography className="colorGray" variant="body1">
                            Offer a white-label version of the app that enterprises can rebrand and sell as their own product.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid xs={12} sm={12} md={4}>
                        <Box sx={{padding:{xs:"5px", sm:"5px", md:"10px"}}}>
                            <img src={icon3} style={{marginBottom:"15px"}}  />
                            <Typography sx={{fontSize:{xs:"18px",sm:"18px",md:"24px"}}} variant="h4" className="colorWhite" style={{marginBottom:"5px"}} >AI Integration & Supports</Typography>
                            <Typography className="colorGray" variant="body1">
                            Allow enterprises to customize the app's branding and user interface to match their own brand identity.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container sx={{ margin: { xs: "32px 0px", sm: "32px 0px", md: "60px 0px" } }}>
                    <Grid xs={12} sm={6}>
                        <Box sx={{textAlign:{xs:"center",sm:"inherit",md:"inherit"}}}>
                            <img src={totalEcommerce} className="width100" />
                        </Box>
                    </Grid>
                    <Grid xs={12} sm={6}>
                        <Box>
                            <Box sx={{ padding: { xs: "0px", sm: "0px", md: "60px 21px" } }}>
                                <Typography sx={{fontSize:{xs:"24px",sm:"24px",md:"48px"}}} className="colorWhite" variant="h3">Total eCommerce Solution</Typography>

                                <div>
                                    <Accordion sx={{ background: "none" }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                        <AccordionSummary
                                            // expandIcon={<ExpandMoreIcon />} 
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography sx={{ display: "flex" }} variant="body1">
                                                <span><img src={CheckIcon} /></span> &nbsp;&nbsp;<span className="colorWhite">Chatbots for customer service</span>
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography className="colorGray" variant="body1">
                                            Automate your customer service by providing AI chatbots for an error free and seamless experience.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion sx={{ background: "none" }} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                        <AccordionSummary
                                            // expandIcon={<ExpandMoreIcon />} 
                                            aria-controls="panel2bh-content"
                                            id="panel2bh-header"
                                        >
                                            <Typography sx={{ display: "flex" }} variant="body1">
                                                <span><img src={CheckIcon} /></span> &nbsp;&nbsp;<span className="colorWhite">Customer segmentation</span>
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography className="colorGray" variant="body1">
                                                With smart automation and top-notch security, it's the perfect solution for teams looking to work smarter.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion sx={{ background: "none" }} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                        <AccordionSummary
                                            // expandIcon={<ExpandMoreIcon />} 
                                            aria-controls="panel3bh-content"
                                            id="panel3bh-header"
                                        >
                                            <Typography sx={{ display: "flex" }} variant="body1">
                                                <span><img src={CheckIcon} /></span> &nbsp;&nbsp;<span className="colorWhite">Voice and Image Searchs</span>
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography className="colorGray" variant="body1">
                                                With smart automation and top-notch security, it's the perfect solution for teams looking to work smarter.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container sx={{ margin: { xs: "32px 0px", sm: "32px 0px", md: "60px 0px" } }}>
                    <Grid xs={12} sm={6} order={{ xs: 2, sm: 2, md:1 }}>
                        <Box>
                            <Box sx={{ padding: { xs: "0px", sm: "0px", md: "60px 21px" } }}>
                                <Typography sx={{fontSize:{xs:"24px",sm:"24px",md:"48px"}}}  className="colorWhite" variant="h3">Increase Your Profits</Typography>

                                <div>
                                    <Accordion sx={{ background: "none" }} expanded={expanded2 === 'panelA'} onChange={handleChangeTab2('panelA')}>
                                        <AccordionSummary
                                            // expandIcon={<ExpandMoreIcon />} 
                                            aria-controls="panelAbh-content"
                                            id="panelAbh-header"
                                        >
                                            <Typography sx={{ display: "flex" }} variant="body1">
                                                <span><img src={CheckIcon} /></span> &nbsp;&nbsp;<span className="colorWhite">Smart Logistics</span>
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography className="colorGray" variant="body1">
                                            Smart logistics aims to enhance inventory management and forecast demand by utilizing real-time data from sensors, RFID tags, or comparable sources.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion sx={{ background: "none" }} expanded={expanded2 === 'panelB'} onChange={handleChangeTab2('panelB')}>
                                        <AccordionSummary
                                            // expandIcon={<ExpandMoreIcon />} 
                                            aria-controls="panelBbh-content"
                                            id="panelBbh-header"
                                        >
                                            <Typography sx={{ display: "flex" }} variant="body1">
                                                <span><img src={CheckIcon} /></span> &nbsp;&nbsp;<span className="colorWhite"> Sales and Demand Forecasting</span>
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography className="colorGray" variant="body1">
                                                We seamlessly integrate AI solutions to your existing systems, ensuring smooth operation and optimal performance.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion sx={{ background: "none" }} expanded={expanded2 === 'panelC'} onChange={handleChangeTab2('panelC')}>
                                        <AccordionSummary
                                            // expandIcon={<ExpandMoreIcon />} 
                                            aria-controls="panelCbh-content"
                                            id="panelCbh-header"
                                        >
                                            <Typography sx={{ display: "flex" }} variant="body1">
                                                <span><img src={CheckIcon} /></span> &nbsp;&nbsp;<span className="colorWhite">Identify Fraudulent Transactions</span>
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography className="colorGray" variant="body1">
                                                We seamlessly integrate AI solutions to your existing systems, ensuring smooth operation and optimal performance.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid xs={12} sm={6} order={{ xs: 1, sm: 1, md:2 }}>
                        <Box sx={{textAlign:{xs:"center",sm:"inherit",md:"inherit"}}}>
                            <img src={increaseProfit} className="width100" />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container sx={{ margin: { xs: "32px 0px", sm: "32px 0px", md: "60px 0px" } }}>
                    <Grid xs={12} sm={6}>
                        <Box sx={{textAlign:{xs:"center",sm:"inherit",md:"inherit"}}} >
                            <img src={putCustom} className="width100" />
                        </Box>
                    </Grid>
                    <Grid xs={12} sm={6}>
                        <Box>
                            <Box sx={{ padding: { xs: "0px", sm: "0px", md: "60px 21px" } }}>
                                <Typography sx={{fontSize:{xs:"24px",sm:"24px",md:"48px"}}}  className="colorWhite" variant="h3">Put the Custom in Your Customers</Typography>

                                <div>
                                    <Accordion sx={{ background: "none" }} expanded={expanded3 === 'panelD'} onChange={handleChangeThree('panelD')}>
                                        <AccordionSummary
                                            // expandIcon={<ExpandMoreIcon />} 
                                            aria-controls="panelDbh-content"
                                            id="panelDbh-header"
                                        >
                                            <Typography sx={{ display: "flex" }} variant="body1">
                                                <span><img src={CheckIcon} /></span> &nbsp;&nbsp;<span className="colorWhite">Tailored Suggestions</span>
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography className="colorGray" variant="body1">
                                            By studying customer clicks and visits, the recommendation engine produces tailored suggestions, no matter the customer count.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion sx={{ background: "none" }} expanded={expanded3 === 'panelE'} onChange={handleChangeThree('panelE')}>
                                        <AccordionSummary
                                            // expandIcon={<ExpandMoreIcon />} 
                                            aria-controls="panelEbh-content"
                                            id="panelEbh-header"
                                        >
                                            <Typography sx={{ display: "flex" }} variant="body1">
                                                <span><img src={CheckIcon} /></span> &nbsp;&nbsp;<span className="colorWhite"> Dynamic Pricing</span>
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography className="colorGray" variant="body1">
                                                We recognize the distinctive challenges and opportunities in every industry, providing custom AI solutions designed to address your specific needs.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion sx={{ background: "none" }} expanded={expanded3 === 'panelF'} onChange={handleChangeThree('panelF')}>
                                        <AccordionSummary
                                            // expandIcon={<ExpandMoreIcon />} 
                                            aria-controls="panelFbh-content"
                                            id="panelFbh-header"
                                        >
                                            <Typography sx={{ display: "flex" }} variant="body1">
                                                <span><img src={CheckIcon} /></span> &nbsp;&nbsp;<span className="colorWhite">Automated Controls</span>
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography className="colorGray" variant="body1">
                                                We recognize the distinctive challenges and opportunities in every industry, providing custom AI solutions designed to address your specific needs.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Grid className="aiInYourField" container sx={{ margin: { xs: "0px 0px", sm: "0px 0px", md: "60px 0px" } }}>
                    <Grid xs={12} sm={12} md={1}>
                    </Grid>
                    <Grid xs={12} sm={12} md={10}>
                        <Box sx={{ textAlign: "center" }}>
                            <Box sx={{ zIndex: "1", position: "relative", padding: { xs: "50px 0px", sm: "auto", md: "0 162px" } }}>
                                <Typography sx={{ padding: { xs: "50px 0px", sm: "auto" } }} variant="h3" className="colorWhite">
                                    Be the pioneers of <span className="borderImage2">AI</span> in your field.
                                </Typography>
                                <Typography sx={{ margin: "20px 0" }} className="colorGray" variant="body1">Unleash the power of AI within I2Pify.ai. Upgrade your productivity with I2Pify.ai, the open AI chat app.
                                </Typography>
                            </Box>

                            <GetInTouch />
                        </Box>
                    </Grid>
                    <Grid xs={12} sm={12} md={1}>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

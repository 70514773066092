import { useState } from "react";
import { ApiUrl } from "../siteconfig/config";

const pricingpageapi = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [loading, setLoading] = useState<boolean>(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [error, setError] = useState<string | null>(null);

  const pricingGetApi = async (value:string) => {
    setLoading(true);
    try {
      const resp = await fetch(`${ApiUrl}/api/${value}?populate=*`);
      const res = resp.json();
      return res;
    } catch (e: any) {
      setError(e.message);
    } 
    setLoading(false);
  };
  return { pricingGetApi, loading, error };
};

export default pricingpageapi;

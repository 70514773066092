import React from 'react'

export default function LandingPageFooterContent() {
  return (
    <div className="text-[#A8ACC3] text-base font-normal">
        <p className="mt-5 customFont"> "We believe in empowering every entrepreneur, regardless of their technical background, to bring their visionary tech ideas to life. Because in the digital age, no dream should be held back by a skill gap."</p>
        <p className="mt-10 customFont">Copyright © 2024</p>
    </div>
  )
}


/* eslint-disable jsx-a11y/alt-text */
import { Box, Tooltip, Typography } from "@mui/material";

export const SingleCard = (product: any) => {

  const { attributes } = product;
  const { description, author,name,logo, designation } = attributes;
  const styles = {
    paperContainer: {
      background: `linear-gradient(90deg, rgba(14, 12, 21, 0.50) 20.84%, rgba(14, 12, 21, 0.00) 50.07%),url(${product?.attributes?.background?.data?.attributes?.url ?? ''})`,
      backgroundSize:"cover",
      display:"flex"
    },
  };
  return (
    <div className="outer-card" style={styles.paperContainer}>
      <div className="left-side-card">
        <div className="innner-left-side-box">
          <Box
            component="img"
            src={logo?.data?.attributes?.url ?? ""}
            sx={{
              width: { xs: "40px", md: "40px" },
              height: { xs: "40px", md: "40px" },
            }}
            alt="responsive image"
          />
          <span className="logo-name">{name}</span>
        </div>
      </div>
      <div className="right-side-card">
        <div className="inner-para-box">
          <Typography
            sx={{
              color: "#fff",
              padding: "10px",
              fontSize: "23px",
              lineHeight: "27px",
              fontWeight: "400",
              fontFamily: "Source Code Pro",
            }}
            variant="body1"
          >
            {description ?? ""}
          </Typography>
        </div>
        <div>
        <Tooltip title={author}>
          <>
            <span className="inner-para-span">by - {`${author ?? ""}`}</span>
            <br />
            <span className="inner-para-span">{`${designation ?? ""}`}</span>
          </>
        </Tooltip>
        </div>
      </div>
    </div>
  );
};

import {
  Box,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number
) {
  return { name, calories, fat, carbs, protein };
}

// const rows = [
//   createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
//   createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
//   createData("Eclair", 262, 16.0, 24, 6.0),
//   createData("Cupcake", 305, 3.7, 67, 4.3),
//   createData("Gingerbread", 356, 16.0, 49, 3.9),
//   createData("Cupcake", 305, 3.7, 67, 4.3),
//   createData("Gingerbread", 356, 16.0, 49, 3.9),
//   createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
//   createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
//   createData("Eclairsss", 262, 16.0, 24, 6.0),
//   createData("Cupcakesss", 305, 3.7, 67, 4.3),
//   createData("Gingerbreadss", 356, 16.0, 49, 3.9),
//   createData("Cupcaksss", 305, 3.7, 67, 4.3),
//   createData("Gingerbreadss", 356, 16.0, 49, 3.9),
// ];

const rows=[
    {
        name:"Shubham",
        email:"test@gmail.com",
        number:"938736662",
        service:"Fitness",
        brief:"icbuevu",
        propective:"dbedvyu"
    },
    {
        name:"Shubham",
        email:"test@gmail.com",
        number:"938736662",
        service:"Fitness",
        brief:"icbuevu",
        propective:"dbedvyu"
    },
    {
        name:"Shubham",
        email:"test@gmail.com",
        number:"938736662",
        service:"Fitness",
        brief:"icbuevu",
        propective:"dbedvyu"
    },
    {
        name:"Shubham",
        email:"test@gmail.com",
        number:"938736662",
        service:"Fitness",
        brief:"icbuevu",
        propective:"dbedvyu"
    },
    {
        name:"Shubham",
        email:"test@gmail.com",
        number:"938736662",
        service:"Fitness",
        brief:"icbuevu",
        propective:"dbedvyu"
    },
    {
        name:"Shubham",
        email:"test@gmail.com",
        number:"938736662",
        service:"Fitness",
        brief:"icbuevu",
        propective:"dbedvyu"
    },
    {
        name:"Shubham",
        email:"test@gmail.com",
        number:"938736662",
        service:"Fitness",
        brief:"icbuevu",
        propective:"dbedvyu"
    },
    {
        name:"Shubham",
        email:"test@gmail.com",
        number:"938736662",
        service:"Fitness",
        brief:"icbuevu",
        propective:"dbedvyu"
    },
    {
        name:"Shubham",
        email:"test@gmail.com",
        number:"938736662",
        service:"Fitness",
        brief:"icbuevu",
        propective:"dbedvyu"
    },
    {
        name:"Shubham",
        email:"test@gmail.com",
        number:"938736662",
        service:"Fitness",
        brief:"icbuevu",
        propective:"dbedvyu"
    },
    {
        name:"Shubham",
        email:"test@gmail.com",
        number:"938736662",
        service:"Fitness",
        brief:"icbuevu",
        propective:"dbedvyu"
    },
    {
        name:"Shubham",
        email:"test@gmail.com",
        number:"938736662",
        service:"Fitness",
        brief:"icbuevu",
        propective:"dbedvyu"
    },
]

const ClientTable = ({clientData,headerObj}:any) => {
  const [page, setPage] = React.useState(0);
  const rowPerPage = 10;

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage-1);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Box minHeight={500}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
              {headerObj?.map((header: any, index: number) => (
                        <TableCell
                        align="center"
                        >
                          <span
                            style={{
                              fontSize: "14px",
                              letterSpacing: "0.14px",
                            }}
                          >
                            {header?.field?.toUpperCase()}
                          </span>
                        </TableCell>
                      ))}
                {/* <TableCell>Name</TableCell>
                <TableCell className="customFont" align="center">Email</TableCell>
                <TableCell align="center">Number</TableCell>
                <TableCell align="center">Service</TableCell>
                <TableCell align="center">Brief</TableCell>
                <TableCell align="center">Prospective</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {clientData
                .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                ?.map((row:any) => (
                    <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                  {
                    headerObj.map((head: any, headindex: number) => (
                     <TableCell align="center">{  row?.attributes?.[head?.key]}</TableCell>
                   
                    ))
                  }
                   </TableRow>
                  
                  // <TableRow
                  //   key={row.name}
                  //   sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  // >
                  //   <TableCell component="th" scope="row" align="center">
                  //     {row?.attributes.name}
                  //   </TableCell>
                  //   <TableCell align="center">{row?.attributes.email}</TableCell>
                  //   <TableCell align="center">{row?.attributes.phone}</TableCell>
                  //   <TableCell align="center">{row?.attributes.service}</TableCell>
                  //   <TableCell align="center">{row?.attributes.Brief}</TableCell>
                  //   <TableCell align="center">{row?.attributes.propective}</TableCell>
                  // </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
      <div className="flex items-center justify-center mt-[30px]">
        <Pagination
         count={Math.ceil(clientData?.length / rowPerPage)} 
          color="primary"
          onChange={handleChangePage}
        />
      </div>
    </>
  );
};

export default ClientTable;

import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/inc/Navbar";
import Footer from "./components/inc/Footer";
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import Error from "./components/pages/Error";
import Services from "./components/pages/Services";
import CaseStudies from "./components/pages/CaseStudies";
import Contact from "./components/pages/ContactUs";
import Feature from "./components/pages/Feature";
import Pricing from "./components/pages/Pricing";
import ScrollToTop from "./components/siteconfig/scrollToTop";
import LandingPageInstantStudio from "./components/pages/landingPageInstantStudio";
import LandingPagePayPerFeature from "./components/pages/landingPagePayPerFeature";
import ThankYouPage from "./components/pages/ThankYouPage";
import ClientResponse from "./components/pages/ClientResponse";

function App() {

  const [showUpperPart, setShowUpperPart] = useState(true);

  useEffect(() => {
    // Check if the route for instantAppStudio is present, if so, do not render the upper part
    const pathname = window.location.pathname.toLowerCase();
    const instantAppStudioRouteExists = pathname.includes("/landing/instantappstudio")  ||  pathname.includes(
      "/landing/payperfeature"
    )  || pathname.includes("/thankyou") || pathname.includes("/clientresponse") ;
    if(instantAppStudioRouteExists){
      document.body.style.backgroundColor ="#FAFCFF";
    }
    setShowUpperPart(!instantAppStudioRouteExists);
  }, []);

  return (
    <>
     {
      showUpperPart &&
      <BrowserRouter>
        <Navbar />
        <ScrollToTop/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutUs" element={<About />} />
          <Route path="/contactUs" element={<Contact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/feature" element={<Feature />} />
          <Route path="/case-studies" element={<CaseStudies />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="*" element={<Error />} />
        </Routes>
        <Footer />
      </BrowserRouter>
      }
      <BrowserRouter>
      <Routes>
        <Route path="/landing/instantAppStudio" element={<LandingPageInstantStudio/>}/>
        <Route path="/landing/payPerFeature" element={<LandingPagePayPerFeature/>}/>
        <Route path="/thankyou" element={<ThankYouPage/>}/>
        <Route path="/clientresponse" element={<ClientResponse/>}/>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

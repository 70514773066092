/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import "./style.css";
import Services1 from "../../assets/services/services1.png";
import baseImg from "../../assets/feature/base06.png";
import baseImg2 from "../../assets/feature/base04.png";
import baseImg3 from "../../assets/feature/1.png";
import Image1 from "../../assets/feature/IMG (5).png";
import Image2 from "../../assets/feature/IMG (6).png";
import GetInTouch from "./GetInTouch";
import featurePageApi from "../hooks/featurepageapi";
import Gradient from "../../assets/home/gradient (2).png";

export default function Feature() {
  const [keyfeatureData, setfeatureData] = useState<any[]>([]);
  const [chooseData, setChooseData] = useState<any[]>([]);
  const { featureGetApi } = featurePageApi();

  useEffect(() => {
    const fetchData = async (checkVal: any) => {
      switch (checkVal) {
        case "keyfeature":
          const dataOne = await featureGetApi("features?populate=*");
          setfeatureData(dataOne?.data ?? []);
          break;
        case "choose":
          const dataTwo = await featureGetApi(
            "feature-why-choose-uses?populate=*"
          );
          setChooseData(dataTwo?.data ?? []);
          break;
      }
    };
    fetchData("keyfeature");
    fetchData("choose");
  }, []);

  const imgArray = [baseImg, baseImg2, baseImg3, baseImg, baseImg2, baseImg3];

  return (
    <Box>
      <Box className="boxPaddingGrid">
        <Grid
          container
          sx={{
            margin: {
              xs: "100px 0 0 0px",
              sm: "100px 0 0 0px",
              md: "170px 0px",
              lg: "150px 0px",
            },
          }}
        >
          <Grid xs={12} sm={6}>
            <Box>
              <Box
                sx={{
                  padding: {
                    xs: "0px",
                    sm: "0px",
                    md: "25px 38px",
                    lg: "120px 75px",
                  },
                }}
              >
                <Typography
                  className="colorWhite"
                  variant="h3"
                  sx={{
                    fontSize:{xs:"30px",sm:"30px",md:"60px"},
                    fontWeight: "600",
                    lineHeight: "72px",
                  }}
                >
                  i2pify Features{" "}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "400",
                    padding: {
                      xs: "0px",
                      sm: "0px",
                      md: "24px 0px",
                      lg: "24px 0px",
                    },
                    fontSize:{xs:"16px",sm:"16px",md:"24px"},
                    lineHeight: "36px",
                    color: "#757185",
                  }}
                  variant="body1"
                >
                  Embrace AI's power to overcome resource limitations and
                  achieve unprecedented efficiency and innovation.
                </Typography>
                <div></div>
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} sm={6}>
            <Box className="">
              <img src={Services1} className="width100" />
            </Box>
          </Grid>
        </Grid>
        <Typography
          className="colorWhite"
          variant="h3"
          sx={{ fontWeight: "600",lineHeight:"64px",fontSize:{xs:"25px",sm:"25px",md:"48px"}, }}
        >
          Our key Feature
        </Typography>
        <Grid
          container
          sx={{ margin: { xs: "0px 0px", sm: "0px 0px", md: "60px 0px" } }}
        >
          {keyfeatureData.map((item: any, index: number) => (
            <Grid xs={12} sm={6} md={6} lg={4}>
              <Box sx={{ padding: { xs: "5px", sm: "40px 12px", md: "40px 12px" } }}>
                <div className="inner-home-box">
                  <Box
                    component="img"
                    src={imgArray[index]}
                    sx={{
                      width: { xs: "100%", md: "85%" },
                      height: "auto",
                      marginBottom: "15px",
                    }}
                    alt="responsive image"
                  />
                  <div className="inner-text-box">
                    <Typography
                      variant="h4"
                      sx={{ fontSize: { xs: "18px", sm: "18px", md: "24px" } }}
                      className="colorWhite"
                      style={{ marginBottom: "5px", fontWeight: "400",lineHeight:"36px" }}
                    >
                      {item?.attributes?.title ?? ""}
                    </Typography>
                    <Typography
                      className="colorWhite inner-para"
                      variant="body1"
                      sx={{fontWeight:"300"}}
                    >
                      {item?.attributes?.description ?? ""}
                    </Typography>
                    <Box
                      component="img"
                      src={Gradient}
                      sx={{
                        width: { xs: "100%", md: "100%" },
                        height: "auto",
                      }}
                      alt="responsive image"
                      className="hoverImg"
                    />
                  </div>
                </div>
              </Box>
            </Grid>
          ))}
        </Grid>

        <Grid
          container
          sx={{ margin: { xs: "0px 0px", sm: "0px 0px", md: "60px 0px" } }}
        >
          <Grid xs={12} sm={12} md={4}>
            <Box sx={{ padding: { xs: "5px", sm: "5px", md: "10px" } }}>
              <Typography
                variant="h1"
                sx={{ fontSize: { xs: "25px", sm: "25px", md: "35px" ,lg:"48px"},lineHeight:{xs:"auto",sm:"auto",md:"45px",lg:"64px"},
              }}
                className="colorWhite"
                style={{
                  marginBottom: "5px",
                  fontWeight: "600",
                }}
              >
                Revolutionising Pricing Transparency
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={8}>
            <Box sx={{ padding: { xs: "5px", sm: "5px", md: "0px 0px 30px 35px" } }}>
              <p className="feature-para">
                At i2pify, we believe that transparency is the cornerstone of
                trust. We understand the frustrations of not knowing the true
                cost of your software development projects until it's too late.
                Hidden charges, unexpected fees, and shifting budgets can wreak
                havoc on your plans. That's why we've made upfront pricing our
                main feature.
              </p>
            </Box>
          </Grid>
        </Grid>

        <div className="line-added"></div>

        <Typography
          className="colorWhite"
          variant="h3"
          align="center"
          sx={{ fontWeight: "600",fontSize:{xs:"30px",sm:"30px"},lineHeight:{xs:"auto",sm:"auto",md:"64px"} }}
        >
          Upfront Pricing: The i2pify Advantage
        </Typography>

        <Grid
          container
          sx={{
            margin: {
              xs: "32px 0px",
              sm: "32px 0px",
              md: "60px 0px",
              alignItems: "center",
            },
          }}
        >
          <Grid xs={12} sm={6}>
            <Box>
              <Box sx={{ padding: { xs: "0px", sm: "0px", md: "60px 21px" } }}>
                <Typography
                  sx={{
                    fontSize: { xs: "24px", sm: "32px", md: "32px" },
                    fontWeight: "400",
                  }}
                  className="colorWhite"
                  variant="h3"
                >
                  Seamless Scheduling
                </Typography>
                <Typography
                  sx={{ margin: "20px 0" }}
                  className="colorWhite"
                  variant="body1"
                  style={{
                    color: "#ADA8C3",
                    fontWeight: "300",
                    lineHeight: "26px",
                    fontSize: "16px",
                    marginTop: "20px",
                  }}
                >
                  <span className="bold-title-feature">
                    Simplified Process:
                  </span>{" "}
                  Schedule a meeting, and we'll swiftly dive into your project
                  details. We respect your time with a concise, focused initial
                  interaction. <br />
                  <span className="bold-title-feature">
                    AI-Powered Estimates:
                  </span>{" "}
                  In that same call, our advanced AI swiftly calculates precise
                  project estimates, analyzing complexity, scope, and
                  requirements. You get an accurate cost breakdown without
                  delay.
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid xs={12} sm={6}>
            <Box
              sx={{ textAlign: { xs: "center", sm: "inherit", md: "inherit" } }}
            >
              <Box
                component="img"
                src={Image1}
                sx={{
                  width: { xs: "100%", md: "85%" },
                  height: "auto",
                }}
                alt="responsive image"
              />
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            margin: {
              xs: "32px 0px",
              sm: "32px 0px",
              md: "60px 0px",
              alignItems: "center",
            },
          }}
        >
          <Grid xs={12} sm={6}>
            <Box
              sx={{ textAlign: { xs: "center", sm: "inherit", md: "inherit" } }}
            >
              <Box
                component="img"
                src={Image2}
                sx={{
                  width: { xs: "100%", md: "85%" },
                  height: "auto",
                }}
                alt="responsive image"
              />
            </Box>
          </Grid>
          <Grid xs={12} sm={6}>
            <Box>
              <Box sx={{ padding: { xs: "0px", sm: "0px", md: "60px 21px" } }}>
                <Typography
                  sx={{
                    fontSize: { xs: "24px", sm: "24px", md: "32px" },
                    fontWeight: "600",
                  }}
                  className="colorWhite"
                  variant="h3"
                >
                  Total Transparency
                </Typography>
                <Typography
                  sx={{ margin: "20px 0" }}
                  className="colorWhite"
                  variant="body1"
                  style={{
                    color: "#ADA8C3",
                    fontWeight: "300",
                    lineHeight: "26px",
                    fontSize: "16px",
                    marginTop: "20px",
                  }}
                >
                  <span className="bold-title-feature">
                    Complete Visibility:
                  </span>{" "}
                  At i2pify, transparency is not just a promise; it's our
                  guarantee. Your estimate covers our service fees and reveals
                  any potential hidden charges like third-party libraries, cloud
                  services, or other expenses.
                  <br />{" "}
                  <span className="bold-title-feature">
                    {" "}
                    No Unexpected Costs:
                  </span>{" "}
                  We know financial clarity matters. With i2pify, you won't
                  encounter surprises – no hidden fees, just a clear view of
                  your project's costs from the beginning.
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{ margin: { xs: "0px 0px", sm: "0px 0px", md: "60px 0px" } }}
        >
          <Box>
            <Box
              sx={{
                zIndex: "1",
                position: "relative",
                padding: { xs: "50px 0px", sm: "auto", md: "0 162px" },
              }}
            >
              <Typography
                sx={{
                  padding: { xs: "50px 0px", sm: "auto", md:'50px 0px' },
                  fontSize: { xs: "24px", sm: "24px", md: '48px' },
                  fontWeight: "600",
                }}
                variant="h3"
                className="colorWhite"
              >
                Why Choose i2pify's Upfront Pricing?
              </Typography>
              <Typography
                sx={{ margin: "20px 0" , fontSize : '18px', fontWeight : '400',letterSpacing : '.45px'}}
                className="colorWhite"
                variant="body1"
              >
                At i2pify, we're not just a software development service – we're
                your trusted partner in achieving your digital goals. Experience
                the i2pify advantage with upfront pricing and unlock a world of
                clarity, control, and confidence in your software development
                journey. Your project's success starts with transparency, and
                we're here to deliver it, every step of the way.
              </Typography>

              <Grid
                container
                sx={{
                  margin: { xs: "0px 0px", sm: "0px 0px", md: "60px 0px" },
                }}
              >
                {chooseData.map((item: any, index: number) => (
                  <Grid xs={12} sm={6} md={10} lg={6}>
                    <Box sx={{ padding: { xs: "5px", sm: "5px", md: "10px" },textAlign:{md:"center"} }}>
                      <div className="inner-home-box">
                        <Box
                          component="img"
                          src={imgArray[index]}
                          sx={{
                            width: { xs: "100%", md: "85%" },
                            height: "auto",
                            marginBottom: "15px",
                          }}
                          alt="responsive image"
                        />
                        {/* <img src={imgArray[index]} style={{ marginBottom: "15px" }} /> */}
                        <div className="inner-text-box-new">
                          <Typography
                            variant="h4"
                            sx={{
                              fontSize: { xs: "18px", sm: "18px", md: "24px" },
                            }}
                            className="colorWhite"
                            style={{ marginBottom: "5px", fontWeight: "400" }}
                          >
                            {item?.attributes?.title ?? ""}
                          </Typography>
                          <Typography
                            className="colorWhite inner-para"
                            variant="body1"
                            style={{ paddingTop: "15px",fontWeight : '300',paddingLeft:"10px" }}
                            sx={{ fontSize: { xs: "14px", sm: "17px", md: "17px",lg:"17px" },}}
                          >
                            {item?.attributes?.description ?? ""}
                          </Typography>
                          <Box
                            component="img"
                            src={Gradient}
                            sx={{
                              width: { xs: "100%", md: "100%" },
                              height: "auto",
                            }}
                            alt="responsive image"
                            className="hoverImg"
                          />
                        </div>
                      </div>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Grid>

        {/* <Grid
          className="aiInYourField"
          container
          sx={{ margin: { xs: "0px 0px", sm: "0px 0px", md: "60px 0px" } }}
        >
          <Grid xs={12} sm={12} md={1}></Grid>
          <Grid xs={12} sm={12} md={10}>
            <Box sx={{ textAlign: "center" }}>
              <Box
                sx={{
                  zIndex: "1",
                  position: "relative",
                  padding: { xs: "50px 0px", sm: "auto", md: "0 50px" },
                }}
              >
                <Typography
                  sx={{ padding: { xs: "50px 0px", sm: "auto" }, fontSize: { xs: "32px", sm: "32px",md:"32px",lg:"60px"} }}
                  variant="h3"
                  className="colorWhite"
                >
                  We offer demo or consultation for{" "}
                  <span className="borderImageCommon borderImageFeature">potential</span> client
                </Typography>
                <Typography
                  sx={{ margin: "20px 0" }}
                  className="colorGray"
                  variant="body1"
                >
                  Unleash the power of AI within I2Pify.ai. Upgrade your
                  productivity with I2Pify.ai, the open AI chat app.
                </Typography>
              </Box>

              <GetInTouch />
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={1}></Grid>
        </Grid> */}
      </Box>
    </Box>
  );
}

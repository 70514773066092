import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import FormSubmitModal from "../formSubmitModal/FormSubmitModal";

const ProjectStartSection = ({headTitle}:any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const style={
    paragraph:{
      width:"90%",
      marginTop:"20px",
      fontWeight:"400",
      color:"#131212"
    } as React.CSSProperties
  }
  return (
    <Box sx={{background: "#C2D3EC"}} className="p-[50px] sm:p-[20px]">
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography
            variant={isMobile ? "h4" : "h2"}
            sx={{ fontWeight: "600", marginTop: "80px" }}
            className="customFont"
          >
            Want to Start A Project?
          </Typography>
          <p className="text-base customFont " style={style.paragraph}>
            Unleash the power of AI within i2Pify.ai. Upgrade your productivity
            with i2Pify.ai
          </p>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormSubmitModal headingTitle={headTitle}  />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProjectStartSection;

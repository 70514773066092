import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import ControlBtnImage from "../../assets/previousBtn.png";
import InvertedComma from "../../assets/InvertedComma.png";
import TestimonialSingleSlider from "./TestimonialSingleSlider";
import { makeStyles } from "@mui/styles";

const sliderData = [
  {
    desc: "i2pify has been a game-changer for us. Their tech expertise and commitment to understanding our needs led to an outstanding solution. Highly recommend for anyone seeking innovative approach to tech services.",
    name: "Shyam Narayan Rai",
    position: "Eservz,CEO",
  },
  {
    desc: "i2pify's cutting-edge solutions were transformative. Their dedication and understanding of our needs led to a standout success. For businesses chasing a tech edge, i2pify is the solution.",
    name: "Abhinav Sharma",
    position: "IMSOM,Executive Committee ",
  },
  {
    desc: "i2pify transformed our government surveys and plans, ensuring accessible benefits for all. Their innovative systems revolutionized processes, offering efficiency and accessibility. I highly recommend i2pify for pioneering government initiatives.",
    name: "Nitin Jain",
    position: "RYMP,Project Manager",
  },
  {
    desc: "i2pify reshaped online farmer consultancy, event updates, and crop-related information. Highly recommend for excellent agricultural support.",
    name: "Vinod Narbar",
    position: "IPL,Project Manager",
  },
  {
    desc: "i2pify transformed Nigeria's campaigns and government plans. Their tailored application brought efficiency to political initiatives. Highly recommended for impactful governance solutions.",
    name: "Nitin Jain",
    position: "CEMITS,Project Manager",
  },
];

const useStyles = makeStyles((theme) => ({
  image: {
    filter: "brightness(1)", // Default brightness
    transition: "filter 0.3s ease", // Adding transition for smooth effect
  },

  controlBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "45px",
    height: "45px",
    borderRadius: "50%",
    border: "1px solid #131212",
    transition: "background-color 0.3s ease",
    cursor: "pointer",
    margin: "0 10px",
    "&:hover": {
      backgroundColor: "#5938DD",
      border: "none",
      "& $image": {
        filter: "brightness(0) invert(1)",
      },
    },
  },
}));

const TestimonialSection = () => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const style = {
    controllerButton: {
      display: "flex",
      // flex: 1,
      alignSelf: "center",
      justifyContent: "end",
    } as React.CSSProperties,
    lowerSectionBox: {
      display: "flex",
      marginTop: "30px",
    } as React.CSSProperties,
    designationContainer: {} as React.CSSProperties,
  };

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const classes = useStyles();
  

  const prevSlide=()=>{
    if(activeIndex >0){
        setActiveIndex(activeIndex-1) 
    }
  }

  const nextSlide=()=>{
    if(activeIndex < sliderData?.length-1){
        setActiveIndex(activeIndex+1) 
    }else{
        setActiveIndex(0)   
    }
  }

  return (
    <Box sx={{padding:"150px 40px"}}>
      <div className="flex items-center flex-wrap">
        <Typography variant={isMobile ? "h4" : "h3"} fontWeight={"600"} className="basis-1/2 sm:basis-full customFont">
          See How We Made Love Our Service
        </Typography>
        <Box style={style.controllerButton} className="basis-1/2 sm:basis-full mt-[20px]  ">
          <Box className={classes.controlBtn}>
            <img
              src={ControlBtnImage}
              className={classes.image}
              alt="Not_found"
              onClick={prevSlide}
            />
          </Box>
          <Box className={classes.controlBtn}>
            <img
              src={ControlBtnImage}
              style={{ transform: "rotate(180deg)" }}
              className={classes.image}
              alt="Not_found"
              onClick={nextSlide}
            />
          </Box>
        </Box>
      </div>
      <Box style={style.lowerSectionBox} className="flex-wrap">
        <Box className="flex basis-6/12 items-center  sm:basis-full">
          <img src={InvertedComma} alt="Not_Found" className="sm:w-[100px]" />
        </Box>
        {sliderData?.map((item, index) => (
          <TestimonialSingleSlider
            sliderData={item}
            index={index}
            activeIndex={activeIndex}
          />
        ))}
      </Box>
    </Box>
  );
};

export default TestimonialSection;

import { Box } from '@mui/material'
import React from 'react'
import Slider from 'react-slick'
import EservzLogo from "../../assets/home/1.png";
import RympLogo from "../../assets/home/4.png";
import SimplySimpleLogo from "../../assets/home/3.png";
import ImsomLogo from "../../assets/home/2.png";
import IplLogo from '../../assets/home/5.png';
import ClientSingleCard from '../clientSingleCard/ClientSingleCard';

var settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed:3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

const ClientSection = () => {
    const SingleCardSLiderArr = [
        EservzLogo,
        SimplySimpleLogo,
        RympLogo,
        ImsomLogo,
        IplLogo
        // CemetsLogo,
      ];
  return (
    <>
       <Box sx={{background:"#FAFCFF"}}>
            <Slider {...settings}>
              {SingleCardSLiderArr.map((clientLogo: any) => (
                <div className="single-card-client">
                <ClientSingleCard image={clientLogo} />
                </div>
              ))}
            </Slider>
          </Box>
    </>
  )
}

export default ClientSection
import React, { useEffect, useState } from "react";
import ClientTable from "../clinetTableResponse/ClientTable";
import { Container, Pagination, Typography } from "@mui/material";
import axios from "axios";
import { ApiUrl } from "../siteconfig/config";

const headerObj = [
  {
    field: "name",
    key: "name",
  },
  {
    field: "email",
    key: "email",
  },
  {
    field: "number",
    key: "phone",
  },
  {
    field: "service",
    key: "service",
  },
  {
    field: "breif",
    key: "Brief",
  },
  {
    field: "prospective",
    key: "prospective",
  },
 
];

const ClientResponse = () => {

  const [clientData,setClientData]=useState<any>([])

  useEffect(() => {
    const fetchPayPerFeatures = async () => {
        try {
            const token = process.env.REACT_APP_SECRET_KEY; // Replace this with your actual bearer token
            const response = await axios.get(`${ApiUrl}/api/payperfeatures`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            // Handle response data
            const {data}=response?.data
            setClientData(data)
        } catch (error) {
            // Handle error
            console.error('Error fetching pay per features:', error);
        }
    };

    fetchPayPerFeatures();
}, []);

  return (
    <>
      <Container className="mt-[50px]">
        <Typography
          variant="h4"
          style={{ margin: "30px 0", fontWeight: "500" }}
          className="customFont"
        >
          Client Response Data
        </Typography>
        <ClientTable clientData={clientData} headerObj ={headerObj}/>
      </Container>
    </>
  );
};

export default ClientResponse;

/* eslint-disable jsx-a11y/alt-text */
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import "./style.css";
import AboutUs from "../../assets/About/line.png";
import Director from "../../assets/About/pavan__pathak.jpg";
import Avatar from "../../assets/About/avatar.png";
import BaseM from "../../assets/About/BaseM.png";
import Base from "../../assets/About/Base.png";
// import Img1 from "../../assets/About/IMG.png";
import Img2 from "../../assets/About/IMG (2).png";
// import Img3 from "../../assets/About/IMG (3).png";
// import Img4 from "../../assets/About/IMG (4).png";
import Icon from "../../assets/About/icon.png";
import OurTeam from "./OurTeam";
import GetInTouch from "./GetInTouch";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import aboutusPageApi from "../hooks/aboutuspageapi";
import { ApiUrl } from "../siteconfig/config";
// import { ApiUrl } from "../siteconfig/config";

export default function About() {
  const [teamData, setTeamData] = useState<any[]>([]);
  const [infoData, setInfoData] = useState<any[]>([]);
  const { aboutusGetApi } = aboutusPageApi();

const theme = useTheme();
const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    const fetchData = async (checkVal: any) => {
      switch (checkVal) {
        case "team":
          const dataOne = await aboutusGetApi("teams?populate=*");
          setTeamData(dataOne?.data ?? []);
          break;
        case "info":
          const dataTwo = await aboutusGetApi("infos?populate=*");
          setInfoData(dataTwo?.data ?? []);
          break;
      }
    };
    fetchData("team");
    fetchData("info");
  }, []);
  let OurTeams: any = [
    {
      img: Director,
      name: "Pawan Pathak",
      destination: "CEO",
    },
    {
      img: Avatar,
      name: "Ravi Chauhan",
      destination: "CEO",
    },
    {
      img: Avatar,
      name: "Aayush Chourasia",
      destination: "AI Expert",
    },
    {
      img: Avatar,
      name: "Anshu",
      destination: "AI Expert",
    },
    {
      img: Avatar,
      name: "Meena Joshi",
      destination: "Quality Tester",
    },
    {
      img: Avatar,
      name: "Neenu Bisht",
      destination: "UX Designer",
    },
    {
      img: Avatar,
      name: "Souvik Pal",
      destination: "Product Manager",
    },
  ];

  // const ContentArr: any[] = [
  //   {
  //     postion: "left",
  //     image: Img2,
  //     content:
  //       "Pawan, the founder, was no stranger to the frustrations of the industry. Having worked in multiple tech companies, he experienced firsthand the pervasive issue of projects never being delivered on time",
  //     title: "The Frustrations of Software Development",
  //   },
  //   {
  //     postion: "right",
  //     image: Img1,
  //     content:
  //       "With this firsthand knowledge of industry woes, John recognized the transformative potential of generative AI in the software development landscape. He envisioned i2pify.ai as an AI-powered platform capable of revolutionising project delivery, guaranteeing high-quality work, and ensuring fair compensation.",
  //     title: "The Vision of i2pify.ai",
  //   },
  //   {
  //     postion: "left",
  //     image: Img3,
  //     content:
  //       "With unwavering determination, John's team embarked on the journey to bring i2pify.ai to life. They harnessed the capabilities of generative AI to create a platform that streamlined the software development process, promising timely deliveries and improved project management.",
  //     title: "Building i2pify.ai",
  //   },
  //   {
  //     postion: "right",
  //     image: Img4,
  //     content:
  //       "i2pify.ai is leading the way in software development, revolutionizing the industry. Our mission is to empower developers, build trust with clients, and transform the software development landscape. With a vision for growth, efficiency, and collaboration, i2pify.ai is poised to usher in a new era of excellence in software development. Join us on this remarkable journey towards a brighter future for the industry",
  //     title: "The Future Beckons",
  //   },
  // ];

  return (
    <Box>
      <Box className="boxPaddingGrid">
        <Grid
          container
          sx={{
            margin: {
              xs: "100px 0 0 0px",
              sm: "100px 0 0 0px",
              md: "175px 0px",
              lg: "150px 0px",
            },
          }}
        >
          <Grid xs={12} sm={6}>
            <Box>
              <Box
                sx={{
                  padding: {
                    xs: "0px",
                    sm: "0px",
                    md: "25px 38px",
                    lg: "120px 75px",
                  },
                }}
              >
                <Typography
                  className="colorWhite"
                  variant="h3"
                  sx={{
                    fontSize: {xs:"30px",sm:"30px",md:"60px"},
                    fontWeight: "600",
                    lineHeight: "72px",
                  }}
                >
                  About Us
                </Typography>
                <Typography
                  sx={{
                    fontSize: {xs:"15px",sm:"20px",md:"20px"},
                    marginTop: "24px",
                    lineHeight: "36px",
                    color:"#ADA8C3",
                    fontWeight : '400',
                    fontFamily : 'Sora !important',
                    fontStyle : 'normal'
                  }}
                  className="colorGray"
                  variant="body1"
                >
                  "We believe in empowering every entrepreneur, regardless of
                  their technical background, to bring their visionary tech
                  ideas to life. Because in the digital age, no dream should be
                  held back by a skill gap."
                </Typography>
                <div></div>
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} sm={6} >
            <Box
              sx={{ margin: { xs: "32px 0px", sm: "32px 0px", md: "0px 0px" } }}
            >
              <Box
                component="img"
                src={AboutUs}
                sx={{
                  width: { xs: "100%", md: "85%" },
                  height: "auto",
                  marginBottom: "15px",
                }}
                alt="responsive image"
              />
            </Box>
          </Grid>
        </Grid>
        {/* <Grid
          container
          sx={{ margin: { xs: "32px 0px", sm: "32px 0px", md: "0px 0px" } }}
        >
          <Grid xs={12} sm={12}>
            <Box>
              <Box sx={{ padding: { xs: "0px", sm: "0px", md: "15px 21px" } }}>
                <Box sx={{ position: "relative" }}>
                  <Box
                    sx={{
                      position: "absolute",
                      top: {
                        xs: "-35px",
                        sm: "-35px",
                        md: "-25px",
                        lg: "-25px",
                      },
                      left: " 59px",
                      width: { xs: "100px", sm: "100px", md: "auto" },
                    }}
                  >
                    <img style={{ width: "60px" }} src={Icon} />
                  </Box>
                  <Box
                    sx={{
                      display: { xs: "none", sm: "none", md: "block" },
                      textAlign: { xs: "center", sm: "inherit", md: "inherit" },
                    }}
                  >
                    <img src={Base} className="width100" />
                  </Box>
                  <Box
                    sx={{
                      display: { xs: "block", sm: "block", md: "none" },
                      textAlign: { xs: "center", sm: "inherit", md: "inherit" },
                    }}
                  >
                     <Box
                      component="img"
                      // src={Img2}
                      src={BaseM}
                      sx={{
                        width: { xs: "100%", md: "100%" },
                        height: "auto",
                      }}
                      alt="responsive image"
                    />
                  </Box>

                  <Box
                    sx={{
                      position: "absolute",
                      top: { xs: "2%", sm: "10%", md: "0%", lg: "0%" },
                      padding: { xs: "20px", sm: "100px 50px", md: "40px",lg:"110px 80px 110px 80px" },
                    }}
                  >
                    <Grid container>
                      <Grid xs={12} sm={12} md={9}>
                        <Box
                          className="textDirector"
                          sx={{
                            padding: { xs: "0px", sm: "0px", md: "0" },
                          }}
                        >
                          <Typography
                            className="colorWhite"
                            variant="h3"
                            sx={{
                              fontSize: {
                                xs: "18px",
                                sm: "30px",
                                md: "20px",
                                lg: "32px",
                              },
                              
                              marginBottom:{xs:"20px",sm:"30px",md:"25px",lg:""},
                              lineHeight:{xs:"25px",sm:"30px",md:"40px"},
                              fontFamily: "Source Code Pro !important",
                            }}
                          >
                            We see a future where AI is a trusted collaborator
                            for every individual. Where AI enhances capabilities
                            & works in harmony with people’s skills & knowledge,
                            empowering them to achieve more than ever before.
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid xs={12} sm={12} md={3}>
                        <Box
                          sx={{
                            display: { xs: "flex", sm: "flex", md: "grid" },
                            justifyContent: "space-around",
                          }}
                        >
                          <img src={Director} className="width100Item" />
                          <Box sx={{ marginLeft: "5px" }}>
                            <Typography
                              sx={{
                                fontSize: "18px",
                                fontWeight: "600",
                                marginTop: "30px",
                              }}
                              className="colorWhite"
                              variant="h5"
                            >
                              Pawan Pathak
                            </Typography>
                            <Typography
                              sx={{ fontSize: "14px", fontWeight: "600" }}
                              className="colorGray"
                              variant="body1"
                            >
                              CEO
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid> */}

       

        <Grid
          container
          sx={{ margin: { xs: "32px 0px", sm: "32px 0px", md: "60px 0px" } }}
        >
          <Grid xs={12} sm={6} md={6} lg={6}>
            <Typography
              sx={{ fontSize: { xs: "35px", sm: "48px", md: "48px" },width:{xs:"auto",sm:"auto",md:"450px"} }}
              className="colorWhite"
              variant="h3"
              style={{
                // width: "450px",
                paddingTop: "3.5rem",
                fontWeight: "600",
              }}
            >
              The Origin Story of i2pify.ai
            </Typography>
          </Grid>
          <Grid xs={12} sm={6}>
            <Box>
              <Box sx={{ padding: { xs: "30px 0", sm: "20px 0", md: "60px 21px" } }}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "24px",
                      sm: "24px",
                      md: "24px",
                      fontWeight: "600",
                    },
                  }}
                  className="colorWhite"
                  variant="h3"
                >
                  The Genesis of i2pify.ai
                </Typography>
                <Typography
                  sx={{ margin: "20px 0",color:"#ADA8C3",fontWeight:"300" }}
                  variant="body1"
                >
                  {" "}
                  In the dynamic world of software development, led by Pawan, a
                  team of passionate developers grappled with persistent
                  challenges. Developers took unscheduled leaves, outsourcing
                  often led to subpar results, payments were frequently delayed,
                  and clients frequently altered project requirements without
                  adjusting costs. These trials paved the way for the inception
                  of i2pify.ai.
                </Typography>
              </Box>
            </Box>
          </Grid>

          {infoData.map((items: any, index: number) => (
            <>
              <Grid  xs={12} sm={6}  sx={{ marginTop: { xs: "10px", sm: "30px", md: "30px",lg:"100px" } }}>
                {index % 2 === 0 && (
                  <Box 
                   order={isSmallScreen ? 2 : 1}
                    sx={{
                      textAlign: { xs: "center", sm: "inherit", md: "inherit" },
                    }}
                  >
                    <Box
                      component="img"
                      // src={Img2}
                      src={`${items?.attributes?.image?.data?.attributes?.url}`}
                      sx={{
                        width: { xs: "100%", md: "85%" },
                        height: "auto",
                      }}
                      alt="responsive image"
                    />
                  </Box>
                )}
              </Grid>
              <Grid xs={12} sm={6}  sx={{ marginTop: { xs: "10px", sm: "30px", md: "30px",lg:"100px" } }}>
                <Box>
                  <Box
                    sx={{ padding: { xs: "0px", sm: "20px 30px", md: "50px" ,lg:"60px 85px"} }}
                  >
                    <div className="chapter-box">
                        <svg xmlns="http://www.w3.org/2000/svg" width="5" height="14" viewBox="0 0 5 14" fill="none">
                          <path d="M4.53906 0.849609H0.539062V12.8496H4.53906" stroke="url(#paint0_angular_1687_366)"/>
                          <defs>
                          <radialGradient id="paint0_angular_1687_366" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2.53906 6.84961) rotate(90) scale(6 2)">
                          <stop offset="0.0993526" stop-color="#FFC876"/>
                          <stop offset="0.427081" stop-color="#79FFF7"/>
                          <stop offset="0.718753" stop-color="#9F53FF"/>
                          <stop offset="1" stop-color="#FF98E2"/>
                          </radialGradient>
                          </defs>
                        </svg>
                        <span className="chapter-para">Chapter {`${index+1}`}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="14" viewBox="0 0 6 14" fill="none">
                          <path d="M0.539062 0.849609H4.53906V12.8496H0.539062" stroke="url(#paint0_angular_1687_368)"/>
                          <defs>
                          <radialGradient id="paint0_angular_1687_368" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2.53906 6.84961) rotate(90) scale(6 2)">
                          <stop offset="0.0993526" stop-color="#FFC876"/>
                          <stop offset="0.427081" stop-color="#79FFF7"/>
                          <stop offset="0.718753" stop-color="#9F53FF"/>
                          <stop offset="1" stop-color="#FF98E2"/>
                          </radialGradient>
                          </defs>
                        </svg>
                    </div>
                    <Typography
                      sx={{
                        fontSize: { xs: "24px", sm: "24px", md: "32px" },
                        fontWeight: "400",
                        marginTop:{xs:"24px",sm:"24px",md:"24px"}
                      }}
                      className="colorWhite"
                      variant="h3"
                    >
                      {items?.attributes?.title ?? ""}
                    </Typography>
                    <Typography
                      sx={{ margin: "20px 0",color:"#ADA8C3",lineHeight:"26px",fontWeight:"300" }}
                      className="colorGray"
                      variant="body1"
                    >
                      {items?.attributes?.description ?? ""}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid xs={12} sm={6}  sx={{ marginTop: { xs: "10px", sm: "30px", md: "30px",lg:"100px" } }}>
                {index % 2 !== 0 && (
                  <Box
                    sx={{
                      textAlign: { xs: "center", sm: "inherit", md: "inherit" },
                    }}
                  >
                    <Box
                      component="img"
                      src={`${items?.attributes?.image?.data?.attributes?.url}`}
                      // src={Img2}
                      sx={{
                        width: { xs: "100%", md: "85%" },
                        height: "auto",
                      }}
                      alt="responsive image"
                    />
                  </Box>
                )}
              </Grid>
            </>
          ))}
        </Grid>

        <Grid
          container
          sx={{ margin: { xs: "32px 0px", sm: "32px 0px", md: "0px 0px",lg:"0px 0px 80px 0px" } }}
        >
          <Grid xs={12} sm={12}>
            <Box>
              <Box
                sx={{
                  padding: { xs: "0px", sm: "0px", md: "30px 21px" },
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: { xs: "32px", sm: "32px", md: "48px" },
                    fontWeight: "600",
                  }}
                  className="colorWhite"
                >
                  Our Team
                </Typography>
                <Typography
                  className="#86858A"
                  sx={{ marginTop: "10px" }}
                  style={{color:"#757185"}}
                  variant="body1"
                >
                  Meet the people who are building the future of your dreams.
                </Typography>
              </Box>
              <Grid container spacing={2} sx={{justifyContent:"center", margin: { xs: "32px 0px", sm: "32px 0px", md: "0px 0px" }}}>
              {
                  teamData.slice(0,2).map((item:any,i:any)=>(
                    <Grid xs={6} sm={6} md={4} lg={3} sx={{ marginBottom: "20px" }} alignSelf="center">
                    <Box sx={{ display: { xs: "grid", sm: "grid", md: "grid" }, justifyContent: "space-around" }}>
                      <div className="team-img-box" style={{width:"100%",textAlign:"center"}}>
                      <img  src={`${item?.attributes?.image?.data?.attributes?.url}`}  className='widthOurTeam' />
                      </div>
                      <Box sx={{ marginLeft: "5px",textAlign:"center" }}>
                        <Typography sx={{ fontSize: "18px", fontWeight: "600", marginTop: "30px" }} className="colorWhite" variant="h5">{item?.attributes?.name ?? ''}</Typography>
                        <Typography sx={{ fontSize: "14px", fontWeight: "600" }} className="colorGray" variant="body1">{item?.attributes?.position  ?? ''}</Typography>
                      </Box>
                    </Box>
                    </Grid>
                  ))
                }
                </Grid>
              <Grid
                container
                sx={{
                  margin: { xs: "32px 0px", sm: "32px 0px", md: "0px 0px" },
                }}
              >
                {teamData.slice(2).map((item: any, i: any) => (
                  <OurTeam key={i} item={item} />
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>




        {/* <Grid
          className="aiInYourField"
          container
          sx={{ margin: { xs: "0px 0px", sm: "0px 0px", md: "60px 0px" } }}
        >
          <Grid xs={12} sm={12} md={1}></Grid>
          <Grid xs={12} sm={12} md={10}>
            <Box sx={{ textAlign: "center" }}>
              <Box
                sx={{
                  zIndex: "1",
                  position: "relative",
                  padding: { xs: "50px 0px", sm: "auto", md: "0 50px" },
                }}
              >
                <Typography
                  sx={{
                    padding: { xs: "50px 0px", sm: "auto" },
                    fontWeight: "600",
                    fontSize: {xs:"32px",sm:"32px",md:"60px" },
                  }}
                  variant="h3"
                  className="colorWhite"
                >
                  We offer demo or consultation <br />
                  for <span className="borderImageCommon borderImageAbout">potential</span> client
                </Typography>
                <Typography
                  sx={{ margin: "20px 0",color:"#757185",lineHeight:"32px",fontSize:"20px" }}
                  variant="body1"
                >
                  Unleash the power of AI within I2Pify.ai. Upgrade your
                  productivity with I2Pify.ai, the open AI chat app.
                </Typography>
              </Box>

              <GetInTouch />
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={1}></Grid>
        </Grid> */}
      </Box>
    </Box>
  );
}

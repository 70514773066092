import { Box, Typography } from "@mui/material";
import React from "react";

const SingleCardBox = ({ data }: any) => {
  const style = {
    singleBoxContainer: {
      display: "flex",
      alignItems: "flex-start",
      marginTop: "30px",
    } as React.CSSProperties,
    iconBox: {
      background: "#DED7F7",
      width: "45px",
      height: "45px",
      padding: "20px",
      borderRadius: "50%",
      display:"flex",
      alignItems:"center",
      justifyContent:"center"
    } as React.CSSProperties,
  };
  return (
    <Box style={style.singleBoxContainer}>
      <Box style={style.iconBox}>
        <img src={data?.icon} alt="Not-Found" style={{maxHeight:"auto !importanat"}} />
      </Box>
      <div className="ml-[20px]">
        <Typography variant="h6" fontSize={"600"} className="customFont">
          {data?.title}
        </Typography>
        <Typography color={"#4E4E50"} variant="body1" marginTop={"10px"} className="customFont">
          {data?.description}
        </Typography>
      </div>
    </Box>
  );
};

export default SingleCardBox;

import React from 'react'
import ServiceIMG1 from "../../assets/CustomizeSolution/Service card.png"
import ServiceIMG2 from "../../assets/CustomizeSolution/Frame427318915(2).png"
import ServiceIMG3 from "../../assets/CustomizeSolution/Servicecard(2).png"
import ServiceIMG4 from "../../assets/CustomizeSolution/Image.png"
import ServiceIMG5 from "../../assets/CustomizeSolution/Frame427318915.png"
import ServiceIMG6 from "../../assets/CustomizeSolution/Servicecard.png"
import { useState } from "react";
import { Box, Typography } from '@mui/material'

export default function CustomizeSolutionCard() {
    const [solutionList] = useState([
        {
            img: ServiceIMG1,
            title: "E-commerce", 
            description: "From storefront to checkout, empower your online business with our AI-driven platform",
        },
        {
            img: ServiceIMG2,
            title: "Fitness app", 
            description: "Achieve your fitness goals with a personalised app designed to motivate and track your progress",
        },
        {
            img: ServiceIMG3,
            title: "Health app app", 
            description: "Transform healthcare delivery with secure, user-friendly apps for patient care and medical professionals",
        },
        {
            img: ServiceIMG4,
            title: "Education and Learning", 
            description: "Revolutionise education with interactive platforms that adapt to each student's learning style",
        },
        {
            img: ServiceIMG5,
            title: "Transportation", 
            description: "Navigate the future of transportation with AI-powered solutions for logistics, ride-sharing, and more",
        },
        {
            img: ServiceIMG6,
            title: "IOT", 
            description: "Connect and control IoT devices seamlessly with intelligent interfaces and data management",
        },
    ]);      


  return (
    <div className='flex flex-wrap'> 
        {solutionList.map((solution, i ) => (
        <div key={i} className='p-2.5 w-1/3 sm:w-full md:w-1/2'>
            <img className='w-full' src={solution.img} alt="IMG" />
            <h4 className='my-2	text-2xl font-medium'>{solution.title}</h4>
            <p className='text-base	font-medium text-[#4E4E50] '>{solution.description}</p>
        </div>
        ))}
    </div>
  )
}


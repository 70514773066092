import { Box, Grid, Typography } from "@mui/material";
import { ApiUrl } from "../siteconfig/config";

const Steps = (props: any) => {
    const { heading,path, steps} = props;
    //console.log(steps,path,heading)
    const content = (
      <>
        <Grid xs={12} sx={{ marginTop: "80px",alignSelf : 'stretch' }}>
          <Box textAlign={"center"}>
            <Typography
              sx={{
                marginTop: "10px",
                marginBottom: "20px",
                color: "var(--neutral-01100, #FFF)",
                textAlign: "center",
                fontSize: "48px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "64px",
              }}
              variant="h2"
            >
              {heading ?? ""}
            </Typography>
          </Box>
        </Grid>
        <Box >
          <Grid
            container
            sx={{
               margin:
                { 
                 xs: "0px 0px",
                 sm: "0px 0px",
                 md: "60px 0px"
                },
                fontWeight : '600'
               }}
          >
            {
            steps.map((step:any,index:any):any =>
             (
              <Grid xs={12} sm={12} md={4}>
                <Box sx={{ padding: { xs: "5px", sm: "5px", md: "10px" } }}>

                 <img src={ (path === 'Techify') ? `${step?.attributes?.image?.data?.attributes?.url}` : `${step?.attributes?.icon?.data?.attributes?.url}`   }
                  style={{ marginBottom: "15px" , width : '48px', height : '48px'}} 
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      overflow: 'hidden',
                      color: "var(--neutral-03, #ADA8C3)",
                      textOverflow: "ellipsis",
                      fontSize: { xs: "16px", sm: "16px", md: "16px !important" },
                      marginBottom: "15px",
                      marginTop : '5px',
                      fontFamily: "Sora",
                      fontStyle: "normal",
                      lineHeight: "24px",
                      fontWeight : '300'
                    
                      }}
                  >
                    Step {step?.attributes?.step}
                  </Typography>
                  <Typography
                    sx={{
                       color: "#F6F3FF",
                       fontFamily: "Sora",
                       fontSize: "24px",
                       fontStyle: "normal",
                       fontWeight: "400",
                       lineHeight: "normal",
                       letterSpacing: "-0.72px",
                      }}
                    className="colorGray"
                    variant="body1"
                  >
                    {step?.attributes?.title}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </>
    );
    return content;
  };

  export default Steps;
/* eslint-disable jsx-a11y/alt-text */
import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { ApiUrl } from '../siteconfig/config'

const OurTeam = (props: any) => {

  return (
    <>
      <Grid xs={6} sm={6} md={4} lg={3} sx={{ marginBottom: "20px" }}>
        <Box sx={{ display: { xs: "grid", sm: "grid", md: "grid" }, justifyContent: "space-around" }}>
          <div className="team-img-box" style={{width:"100%",textAlign:"center"}}>
          <img  src={`${props?.item?.attributes?.image?.data?.attributes?.url}`}  className='widthOurTeam' />
          </div>
          <Box sx={{ marginLeft: "5px",textAlign:"center" }}>
            <Typography sx={{ fontSize: "18px", fontWeight: "600", marginTop: "30px" }} className="colorWhite" variant="h5">{props?.item?.attributes?.name ?? ''}</Typography>
            <Typography sx={{ fontSize: "14px", fontWeight: "600" }} className="colorGray" variant="body1">{props?.item?.attributes?.position  ?? ''}</Typography>
          </Box>
        </Box>
      </Grid>
    </>
  )
}

export default OurTeam

import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import "./style.css";
import GetInTouch from "./GetInTouch";
export default function Contact() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const handleClick = (link : string) => {
    window.open(link, '_blank');
  }
  return (
    <Box>
      <Box className="boxPaddingGrid">
        <Box
          sx={{
            padding: {
              xs: "0px",
              sm: "0px",
              md: "125px 38px 25px 38px",
              lg: "120px 75px",
              textAlign: "center",
            },
          }}
        >
          <Typography
            className="colorWhite"
            variant="h3"
            sx={{ fontWeight: "600", fontSize:{xs:"30px",sm:"30px",md:"60px"},marginTop:{xs:"100px",sm:"100px",md:"auto"} }}
          >
            Contact Us
          </Typography>
          <Typography
            sx={{
              marginTop: "15px",
              lineHeight: "36px",
              fontWeight: "400",
              fontSize:{xs:"16px",sm:"16px",md:"24px"},
            }}
            className="colorGray"
            variant="body1"
          >
            Get started with I2Pify.ai{" "}
          </Typography>
          <Box className="icon-box">
            <Grid
              container
              spacing={4}
              sx={{ justifyContent: isSmallScreen ? "flex-start" : "center",zIndex:2 }}
            >
              {/* <Grid item xs={6} sm={3} md={3} lg={3} sx={{
                display : 'flex', alignItems : 'center', gap : '32px',justifyContent : 'center'
              }}>
                <div className="single-icon-box">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 19 18"
                    fill="none"
                    //onClick={e=>handleClick('https://twitter.com/pawanpathak16')}
                  >
                    <g clip-path="url(#clip0_1009_68026)">
                      <path
                        d="M18.1222 4.11189C17.4794 4.37974 16.8365 4.59403 16.0865 4.6476C16.8365 4.21903 17.3722 3.5226 17.6401 2.71903C16.9436 3.1476 16.1936 3.41546 15.3901 3.57617C14.7472 2.87974 13.8365 2.45117 12.8722 2.45117C10.9436 2.45117 9.3365 4.00474 9.3365 5.98689C9.3365 6.25474 9.39007 6.5226 9.44364 6.79046C6.49721 6.62974 3.92578 5.23689 2.15792 3.09403C1.8365 3.62974 1.67578 4.21903 1.67578 4.86189C1.67578 6.09403 2.31864 7.16546 3.22935 7.80831C2.64007 7.80831 2.10435 7.6476 1.62221 7.37974V7.43331C1.62221 9.1476 2.85435 10.5405 4.4615 10.8619C4.14007 10.9155 3.87221 10.969 3.55078 10.969C3.3365 10.969 3.12221 10.969 2.90792 10.9155C3.3365 12.3083 4.67578 13.3262 6.17578 13.3797C4.99721 14.344 3.44364 14.8797 1.78292 14.8797C1.51507 14.8797 1.19364 14.8797 0.925781 14.8262C2.53292 15.7905 4.40792 16.3797 6.39007 16.3797C12.8722 16.3797 16.4079 11.0226 16.4079 6.36189C16.4079 6.20117 16.4079 6.04046 16.4079 5.93331C17.0508 5.3976 17.6401 4.80831 18.1222 4.11189Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1009_68026">
                        <rect
                          width="17.1429"
                          height="17.1429"
                          fill="white"
                          transform="translate(0.979492 0.84375)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </Grid> */}
              <Grid item xs={6} sm={3} md={3} lg={3}  sx={{
                display : 'flex', alignItems : 'center', gap : '32px',justifyContent : 'center'
              }}>
                <div className="single-icon-box">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="41"
                    height="40"
                    viewBox="0 0 41 40"
                    fill="none"
                    onClick={e=>handleClick('https://www.linkedin.com/company/i2pify/')}
                  >
                    <rect
                      x="0.550781"
                      width="40"
                      height="40"
                      rx="20"
                      fill="url(#paint0_angular_1009_68020)"
                    />
                    <path
                      d="M27.6913 11.5H13.5032C12.7283 11.5 12.0508 12.058 12.0508 12.824V27.0431C12.0508 27.8136 12.7283 28.5 13.5032 28.5H27.6869C28.4662 28.5 29.0508 27.8092 29.0508 27.0431V12.824C29.0552 12.058 28.4662 11.5 27.6913 11.5ZM17.3204 25.6703H14.8848V18.0981H17.3204V25.6703ZM16.1867 16.9467H16.169C15.3897 16.9467 14.8848 16.3666 14.8848 15.6404C14.8848 14.9009 15.403 14.3341 16.2 14.3341C16.9971 14.3341 17.4842 14.8965 17.5019 15.6404C17.5019 16.3666 16.9971 16.9467 16.1867 16.9467ZM26.2211 25.6703H23.7856V21.5299C23.7856 20.538 23.4313 19.8605 22.5501 19.8605C21.877 19.8605 21.4785 20.3166 21.3014 20.7594C21.2349 20.9188 21.2172 21.1358 21.2172 21.3572V25.6703H18.7817V18.0981H21.2172V19.152C21.5715 18.6472 22.125 17.9209 23.4136 17.9209C25.0122 17.9209 26.2211 18.9749 26.2211 21.2465V25.6703Z"
                      fill="white"
                    />
                    <defs>
                      <clipPath
                        id="paint0_angular_1009_68020"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(20.5508 20) rotate(121.654) scale(27.3133 25.064)"
                      >
                        <stop offset="0.0993526" stop-color="#2825D1" />
                        <stop offset="0.427081" stop-color="#8651FF" />
                        <stop offset="0.718753" stop-color="#3200F0" />
                        <stop offset="1" stop-color="#D618F0" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </Grid>
              <Grid item xs={6} sm={3} md={3} lg={3}  sx={{
                display : 'flex', alignItems : 'center', gap : '32px',justifyContent : 'center'
              }}>
                <div className="single-icon-box">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 19 18"
                    fill="none"
                    onClick={e=>handleClick('https://www.instagram.com/i2pify/?igshid=YmMyMTA2M2Y%3D')}

                  >
                    <path
                      d="M9.5512 12.1297C11.0503 12.1297 12.2655 10.9145 12.2655 9.41546C12.2655 7.9164 11.0503 6.70117 9.5512 6.70117C8.05214 6.70117 6.83691 7.9164 6.83691 9.41546C6.83691 10.9145 8.05214 12.1297 9.5512 12.1297Z"
                      fill="white"
                    />
                    <path
                      d="M12.9432 1.27344H6.15752C3.53892 1.27344 1.4082 3.40483 1.4082 6.02412V12.8098C1.4082 15.4284 3.5396 17.5592 6.15888 17.5592H12.9446C15.5632 17.5592 17.6939 15.4278 17.6939 12.8085V6.02276C17.6939 3.40415 15.5625 1.27344 12.9432 1.27344ZM9.55106 13.4877C7.30567 13.4877 5.47963 11.6617 5.47963 9.41629C5.47963 7.1709 7.30567 5.34487 9.55106 5.34487C11.7965 5.34487 13.6225 7.1709 13.6225 9.41629C13.6225 11.6617 11.7965 13.4877 9.55106 13.4877ZM14.3011 5.34487C13.9265 5.34487 13.6225 5.04087 13.6225 4.66629C13.6225 4.29172 13.9265 3.98772 14.3011 3.98772C14.6756 3.98772 14.9796 4.29172 14.9796 4.66629C14.9796 5.04087 14.6756 5.34487 14.3011 5.34487Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </Grid>
              <Grid item xs={6} sm={3} md={3} lg={3}  sx={{
                display : 'flex', alignItems : 'center', gap : '32px',justifyContent : 'center'
              }}>
                <div className="single-icon-box">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 19 18"
                    fill="none"
                    onClick={e=>handleClick('https://www.facebook.com/people/I2pify/100089922552719/?mibextid=ZbWKwL')}
                  >
                    <g clip-path="url(#clip0_1009_68044)">
                      <path
                        d="M7.65608 17.4503V10.486H5.26562V7.27176H7.65608V5.02711C7.65608 2.582 9.20401 1.37891 11.3852 1.37891C12.43 1.37891 13.328 1.45669 13.5897 1.49147V4.04675L12.0769 4.04744C10.8907 4.04744 10.6228 4.61114 10.6228 5.43832V7.27176H13.8371L12.7656 10.486H10.6228V17.4503H7.65608Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1009_68044">
                        <rect
                          width="17.1429"
                          height="17.1429"
                          fill="white"
                          transform="translate(0.979492 0.84375)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Grid
          className="aiInYourField"
          container
          sx={{ margin: { xs: "0px 0px", sm: "0px 0px", md: "60px 0px" } }}
        >
          <Grid xs={12} sm={12} md={1}></Grid>
          <Grid xs={12} sm={12} md={10}>
            <Box sx={{ textAlign: "center" }}>
              <Box
                sx={{
                  zIndex: "1",
                  position: "relative",
                  padding: { xs: "50px 0px", sm: "auto", md: "0 55px" },
                }}
              >
                <Typography
                  sx={{
                    padding: { xs: "50px 0px", sm: "auto" },
                    fontWeight: "600",
                    fontSize:{xs:"30px",sm:"30px",md:"60px"},
                  }}
                  variant="h3"
                  className="colorWhite"
                >
                  We offer demo or consultation for{" "}
                  <span className="borderImageCommon borderImageContact">potential</span> client
                </Typography>
                <Typography
                  sx={{ margin: "25px" }}
                  className="colorGray"
                  variant="body1"
                >
                  Unleash the power of AI within I2Pify.ai. Upgrade your
                  <br />
                  productivity with I2Pify.ai
                </Typography>
              </Box>

              <GetInTouch />
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={1}></Grid>
        </Grid>
      </Box>
    </Box>
  );
}

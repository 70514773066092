
export default function InnovationContent() {
  return (
    <div className="basis-1/2 md: flex flex-col justify-center w-full sm:w-full basis-full">
        <h1 className="font-medium text-5xl	leading-20 customFont sm:text-3xl">Where Innovation Meets Simplicity!</h1>
        <p className="font-medium text-xl	mt-9 customFont">"We believe in empowering every entrepreneur, regardless of their technical background, to bring their visionary tech ideas to life. Because in the digital age, no dream should be held back by a skill gap."</p>
    </div>
  )
}


import {Accordion,AccordionSummary,Box,Grid,Typography,} from "@mui/material";
import CheckIcon from "../../assets/CheckIcon.png";
import { useEffect, useState } from "react";
import {  makeStyles } from "@mui/styles";
import GenericButtonSchedule from "../genericButtonSchedule/GenericButtonSchedule";

const useStyles = makeStyles((theme) => ({
  customAccordion: {
    borderBottom: "1px solid rgba(255, 255, 255, 0.10)", 
  },
}));

export default function PricingCards({
  props,
  priceAction,
  index,
  clickModal,
}: any) {
  const classes = useStyles();

  const { title, type, description } = props?.attributes;
  const features = props?.attributes?.pricing_features?.data;
  const { priceMonthly, priceQuarterly, priceAnnually } = props?.attributes;

  const [price, setPrice] = useState<number>(priceMonthly ?? 0);
  const [color, setColor] = useState<string>("");

  useEffect(() => {
    if (priceAction === "MONTHLY") {
      setPrice(priceMonthly);
    }

    if (priceAction === "QUARTERLY") {
      setPrice(priceQuarterly);
    }

    if (priceAction === "ANNUALLY") {
      setPrice(priceAnnually);
    }
  }, [priceAction]);

  useEffect(() => {
    if (type === "Premium" || title === "Premium") {
      setColor("#FF7A71");
    }

    if (type === "Standard" || title === "Standard") {
      setColor("var(--color-01, #AC6AFF)");
    }

    if (type === "Basic" || title === "Basic") {
      setColor("var(--color-02, #FFC876)");
    }

    if (type === "Enterprise" || title === "Enterprise") {
      setColor("#DE00FE");
    }
  }, []);
  

  const content = (
    <>
      <Grid
        item
        xs={4} sm={4} md={4}
        sx={{
          background: "var(--neutral-08, #0E0C15)",
          border: "1px solid var(--neutral-015, rgba(255, 255, 255, 0.10))",
          borderRadius: "22px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          overflow: "auto",
          minWidth: "300px",
          // minHeight:"650px",
          gap: "30px",
          padding:"32px",
            // type === "Standard" ||
            // title === "Standard" ||
            // type === "Enterprise" ||
            // title === "Enterprise"
              // ? "48px 32px"
              // : "32px",
          marginTop: { xs: "40px", sm: "40px", md: "20px", lg: "20px" },
        }}
      >
        <Box>
          <Typography variant="h4" color={color} sx={{ fontWeight: "400" }}>
            {title && (title ?? "")} {type && (type ?? "")}
          </Typography>
        </Box>
        {/* <Box>
          <Typography variant="body2" color="grey" sx={{ fontWeight: "600" }}>
            {title !== "Enterprise" && description}
          </Typography>
        </Box> */}
        {/* {title !== "Enterprise" && (
          <Box>
            <Typography
              variant="h3"
              color="white"
              sx={{ fontWeight: "600", fontSize: "64px" }}
            >
              <span style={{ fontSize: "40px", fontWeight: "400" }}>$</span>
              {price ?? ""}
            </Typography>
          </Box>
        )} */}
        {/* <Box>
          <GenericButtonSchedule clickModal={clickModal} text={title === "Enterprise" ? "contact sales" : "Schedule a call"} />
        </Box> */}

        <Box sx={{minHeight:"440px"}}>
          {features?.map((content: any, index: number) => (
            <Box>
              <Box>
                <Accordion
                  sx={{ background: "none", color: "red" }}
                  expanded={false}
                >
                  <AccordionSummary
                    className={classes.customAccordion}
                    style={{ color: "red" }}
                    id="panel1bh-header"
                  >
                    <Typography sx={{ display: "flex" }} variant="body1">
                      <span style={{ cursor: "pointer" }}>
                        <img src={CheckIcon} alt='image' />
                      </span>
                      &nbsp;&nbsp;
                      <span className="colorWhite">
                        {content?.attributes?.title ?? ""}
                      </span>
                    </Typography>
                  </AccordionSummary>
                </Accordion>
              </Box>
            </Box>
          ))}
        </Box>
      </Grid>
    </>
  );

  return content;
}

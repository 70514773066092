import { Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import SubcriptionBackground from "../../assets/SubscriptionBg.png";
import SingleCardBox from "../singleCardBox/SingleCardBox";





const SubscriptionSection = ({cardData }: any) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  return (
    <div className="sm:mt-16">
      <img
        src={SubcriptionBackground}
        alt="Not-Found"
        style={{ width: "100%", marginTop: "50px" }}
        className="h-[550px] sm:h-full"
      />
      <div className="my-[80px]">
        <Typography variant= {isMobile ? "h4" :"h2"} fontSize={"700"} className="customFont" >
          <span style={{ color: "#5938DD" }}>Boost</span> Your Business
        </Typography>
        <Grid container spacing={2}>
          {cardData?.map((singleData: any) => (
            <Grid item xs={12} md={6}>
              <SingleCardBox data={singleData} />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default SubscriptionSection;

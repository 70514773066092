import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography, TextField, InputAdornment } from "@mui/material";
import "./style.css"
import SearchIcon from '@mui/icons-material/Search';
import Case1 from "../../assets/caseStudies/caseStudies1.png"
import Case1m from "../../assets/caseStudies/caseStudies1m.png"
import Case2 from "../../assets/caseStudies/caseStudies2.png"
import Case2m from "../../assets/caseStudies/caseStudies2m.png"
import Case3 from "../../assets/caseStudies/caseStudies3.png"
import Case3m from "../../assets/caseStudies/caseStudies3m.png"
import ButtonImg from "../../assets/caseStudies/ButtonImg.png"
import { Link } from "react-router-dom";
import GetInTouch from "./GetInTouch";
export default function CaseStudies() {

    return (
        <Box>
            <Grid container>
                <Grid xs={12} sm={12}>
                    <Box className="bannerHead">
                       <Box sx={{padding:{xs:"0px",sm:"0", md:"0 100px"}}}>
                       <Typography sx={{ marginTop: "10px", marginBottom: "20px" }} variant="h3">Case Studies </Typography>
                        <Typography sx={{ marginTop: "10px", marginBottom: "20px" }} variant="body1">Get started with I2Pify.ai - AI chat app today and experience the power of AI in your conversations!</Typography>
                        <Box sx={{ width: "100%" }}>

                            <TextField
                                id="outlined-basic"
                                //   label="Search topic" 
                                autoComplete="off"
                                placeholder="Search topic"
                                sx={{
                                    margin: "15px 0",
                                    width: "100%",
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderRadius: '35px',
                                            borderColor: '#808080',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#fff', // Change to your desired hover color
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#fff', // Set the focused border color to white
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: '#fff',
                                    },
                                }}
                                InputProps={{
                                    sx: {
                                        color: '#84989F',
                                        borderRadius: '35px',
                                        backgroundColor: '#0E0C17D9',
                                        '&:hover': {
                                            borderColor: '#fff', // Change to your desired hover color
                                        },
                                        '&.Mui-focused': {
                                            borderColor: '#fff', // Set the focused border color to white
                                            color: "#fff", // Set the focused text color to white
                                        },
                                    },
                                    startAdornment: ( // Add a startAdornment
                                        <InputAdornment position="start">
                                            <SearchIcon sx={{ color: "#fff" }} />
                                        </InputAdornment>
                                    ),

                                }}
                            />
                        </Box>
                       </Box>
                    </Box>
                </Grid>
            </Grid>
            <Box className="boxPaddingGrid">
                <Grid container sx={{ margin: { xs: "32px 0px", sm: "32px 0px", md: "0px 0px" } }}>
                    <Grid xs={12}>
                        <Box sx={{padding:{xs:"0 0px", sm:"0 0px", md:"0 20px"}}}>
                            <Typography sx={{fontSize:{xs:"32px", sm:"32px",md:"40px"}}} className="colorWhite" variant="h3">Our Case Studies</Typography>
                            <Typography sx={{borderBottom:"1px #808080 solid", margin:"20px 0"}}></Typography>
                        </Box>
                    </Grid>
                    <Grid xs={12} sm={12}>
                        <Box>
                            <Box sx={{ padding: { xs: "0px", sm: "0px", md: "15px 21px" } }}>
                                <Typography className="colorWhite" variant="h3" sx={{ fontSize: { xs: "24px", sm: "24px", md: "32px" }, marginBottom: "25px", }}>AI Going to places</Typography>
                                <Box sx={{ display: { xs: "none", sm: "none", md: "block" }, textAlign: { xs: "center", sm: "inherit", md: "inherit" } }}>
                                    <img src={Case1} className="width100" />
                                </Box>
                                <Box sx={{ display: { xs: "block", sm: "block", md: "none" }, textAlign: { xs: "center", sm: "inherit", md: "inherit" } }}>
                                    <img src={Case1m} className="width100" />
                                </Box>
                                <Typography sx={{ fontSize: { xs: "16px", sm: "16px", md: "16px" }, marginTop: "25px", }} className="colorGray" variant="body1">Converse with your documents in an easy, intuitive way with ChatZy.<br />

                                    Chatzy is the innovative app that harnesses the combined power of LangChain, VectorDB, and LLM to facilitate chat-based interactions with users' own data. It revolutionizes data engagement, making information more accessible, interactive, and enjoyable, while offering intelligent insights and enhancing the overall user experience.</Typography>
                                <Box sx={{ margin: { xs: "30px 0" }, display: "flex", justifyContent: "space-around" }}>
                                    <Link to="#"><img src={ButtonImg} /></Link>
                                </Box>

                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container sx={{ margin: { xs: "32px 0px", sm: "32px 0px", md: "0px 0px" } }}>
                    <Grid xs={12} sm={12}>
                        <Box>
                            <Box sx={{ padding: { xs: "0px", sm: "0px", md: "15px 21px" } }}>
                                <Typography className="colorWhite" variant="h3" sx={{ fontSize: { xs: "24px", sm: "24px", md: "32px" }, marginBottom: "25px", }}>Connect with AI Chatbot</Typography>
                                <Box sx={{ display: { xs: "none", sm: "none", md: "block" }, textAlign: { xs: "center", sm: "inherit", md: "inherit" } }}>
                                    <img src={Case2} className="width100" />
                                </Box>
                                <Box sx={{ display: { xs: "block", sm: "block", md: "none" }, textAlign: { xs: "center", sm: "inherit", md: "inherit" } }}>
                                    <img src={Case2m} className="width100" />
                                </Box>
                                <Typography sx={{ fontSize: { xs: "16px", sm: "16px", md: "16px" }, marginTop: "25px", }} className="colorGray" variant="body1">Connect with the AI chatbot to start the conversation. The chatbot uses natural language processing to understand your queries and provide relevant responses.</Typography>
                                <Box sx={{ margin: { xs: "30px 0" }, display: "flex", justifyContent: "space-around" }}>
                                    <Link to="#"><img src={ButtonImg} /></Link>
                                </Box>

                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container sx={{ margin: { xs: "32px 0px", sm: "32px 0px", md: "0px 0px" } }}>
                    <Grid xs={12} sm={12}>
                        <Box>
                            <Box sx={{ padding: { xs: "0px", sm: "0px", md: "15px 21px" } }}>
                                <Typography className="colorWhite" variant="h3" sx={{ fontSize: { xs: "24px", sm: "24px", md: "32px" }, marginBottom: "25px", }}>Get Personalized Recommendations</Typography>
                                <Box sx={{ display: { xs: "none", sm: "none", md: "block" }, textAlign: { xs: "center", sm: "inherit", md: "inherit" } }}>
                                    <img src={Case3} className="width100" />
                                </Box>
                                <Box sx={{ display: { xs: "block", sm: "block", md: "none" }, textAlign: { xs: "center", sm: "inherit", md: "inherit" } }}>
                                    <img src={Case3m} className="width100" />
                                </Box>
                                <Typography sx={{ fontSize: { xs: "16px", sm: "16px", md: "16px" }, marginTop: "25px", }} className="colorGray" variant="body1">Based on the conversation with the AI chatbot, you will receive personalized recommendations related to your queries. The chatbot is trained to understand your preferences and provide customized suggestions.</Typography>
                                <Box sx={{ margin: { xs: "30px 0" }, display: "flex", justifyContent: "space-around" }}>
                                    <Link to="#"><img src={ButtonImg} /></Link>
                                </Box>

                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Grid className="aiInYourField" container sx={{ margin: { xs: "0px 0px", sm: "0px 0px", md: "60px 0px" } }}>
                    <Grid xs={12} sm={12} md={1}>
                    </Grid>
                    <Grid xs={12} sm={12} md={10}>
                        <Box sx={{ textAlign: "center" }}>
                            <Box sx={{ zIndex: "1", position: "relative", padding: { xs: "50px 0px", sm: "auto", md: "0 162px" } }}>
                                <Typography sx={{ padding: { xs: "50px 0px", sm: "auto" } }} variant="h3" className="colorWhite">
                                    Be the pioneers of <span className="borderImage2">AI</span> in your field.
                                </Typography>
                                <Typography sx={{ margin: "20px 0" }} className="colorGray" variant="body1">Unleash the power of AI within I2Pify.ai. Upgrade your productivity with I2Pify.ai, the open AI chat app.
                                </Typography>
                            </Box>

                            <GetInTouch />
                        </Box>
                    </Grid>
                    <Grid xs={12} sm={12} md={1}>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

import React from 'react'
import ig from "../../../assets/LandingPageFooter/ri_instagram-fill.svg"
import linkdin from "../../../assets/LandingPageFooter/Vector.svg"
import fb from "../../../assets/LandingPageFooter/uil_facebook.svg"


export default function LandingPageFooterSocial() {
  return (
    <div className="mr-20 text-[#A8ACC3] text-base	font-normal	flex flex-col justify-end items-center w-[20%] sm:items-start mx-9  mt-8">
        <p className='customFont'>Social Media</p>
        <div className="flex flex-row py-2">
            <img src={ig} alt="Ig" />
            <img className='mx-5' src={linkdin} alt="Linkdin" />
            <img src={fb} alt="Fb" />
        </div>
    </div>
  )
}


import React from 'react'
import LandingPageFooter from '../LandingPageFooter/LandingPageFooter'
import LandingPageHeader from '../LandingPageHeader/LandingPageHeader'

const Layout = ({children}:any) => {
  return (
    <>
    <LandingPageHeader/>
    <div className='px-[30px] sm:p-[10px] max-w-screen-2xl mx-auto'>
      {children}
      </div>
      <LandingPageFooter />
    </>
  )
}

export default Layout
import React from 'react'
import CustomizeSolutionCard from '../CustomizeSolutionCard'


export default function CustomizeSolution() {
  return (
    <div className="mx-24 my-[70px] sm:mx-2">
        <div className='w-3/4'> 
        <p className='text-6xl font-medium	tracking-wider	text-[#131212] py-5 sm:text-4xl customFont'>Fully <span className="text-[#5938DD] customFont">Customised Solution</span> for your Business</p>
        </div>
        <div className=''>
        <CustomizeSolutionCard/>
        </div>
    </div>

  )
}


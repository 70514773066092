import { Box, Button } from '@mui/material'
import React from 'react'

const CustomButtom = ({title}:any) => {
    const style={
        customBtn:{
            width:"90px",
            height:"90px",
            background:"#5938DD",
            borderRadius:"50%",
            color:"#fff",
            fontWeight:"600",
            padding:"5px"
        } as React.CSSProperties
    }
  return (
    <Box className="flex justify-end sm:justify-start">
    <Button style={style.customBtn}>{title}</Button>
    </Box>
  )
}

export default CustomButtom
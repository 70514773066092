import { Padding } from "@mui/icons-material";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  customAccordion: {
    borderBottom: "1px solid rgba(255, 255, 255, 0.10)",
  },
  buttonBox: {
    position: "relative",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    height: "44px",
    padding: "0 28x",
    fontSize: "8px",
    transition: "color 0.3s",
    width: "100%",
    marginBottom: "6px",
  },
}));

const GenericButtonSchedule = ({ text, clickModal }: any) => {
  const classes = useStyles();
  return (
    <>
      <Box onClick={clickModal} sx={{ cursor: "pointer" }}>
        <Box className={classes.buttonBox}>
          <span className="genericBtnCallShedule">
            {
            <Box sx={{padding : '20px'}}> 
              {text}
            </Box>
             }
            </span>
          <Box sx={{ position: "absolute", top: 0, left: "1px" }}>
            <svg
              className="absolute top-0 left-0"
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="44"
              viewBox="0 0 21 44"
              fill="none"
            >
              <path
                d="M0.879883 8V36C0.879883 40.4183 4.06364 44 7.99099 44H20.8799V0H7.99099C4.06364 0 0.879883 3.58172 0.879883 8Z"
                fill="white"
              />
            </svg>
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: "1.3125rem",
              width: "calc(100% - 2.625rem)",
            }}
          >
            <svg
              width="100%"
              height="44"
              viewBox="0 0 100 44"
              preserveAspectRatio="none"
              fill="white"
            >
              <polygon
                fill="white"
                fill-rule="nonzero"
                points="100 0 100 44 0 44 0 0"
              ></polygon>
            </svg>
          </Box>
          <Box sx={{ position: "absolute", top: 0, right: "1px" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="44"
              viewBox="0 0 21 44"
              fill="none"
            >
              <path
                d="M17.422 10.4692L7.39988 1.85545C6.005 0.656586 4.24645 0 2.43037 0H0.212891V44H5.24106H12.4525C16.7384 44 20.2129 40.4183 20.2129 36V16.6138C20.2129 14.2402 19.1905 11.9892 17.422 10.4692Z"
                fill="white"
              />
            </svg>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default GenericButtonSchedule;

import { useState } from "react";
import { ApiUrl } from "../siteconfig/config";

const featurePageApi = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [loading, setLoading] = useState<boolean>(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [error, setError] = useState<string | null>(null);

  const featureGetApi = async (value:string) => {
    setLoading(true);
    try {
      const resp = await fetch(`${ApiUrl}/api/${value}`);
      const res = resp.json();
      return res;
    } catch (e: any) {
      setError(e.message);
    }
    setLoading(false);
  };

  return { featureGetApi, loading, error };
};

export default featurePageApi;

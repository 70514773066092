import React from 'react'
import iconLogo1  from '../../../assets/HowitWorks/subway_call-2.svg'
import iconLogo2  from '../../../assets/HowitWorks/teenyicons_cost-estimate-solid.svg'
import iconLogo3  from '../../../assets/HowitWorks/solar_wallet-money-bold-duotone (2).svg'
import iconLogo4  from '../../../assets/HowitWorks/mdi_truck-delivery.svg'
import iconLogo5  from '../../../assets/HowitWorks/ic_twotone-live-tv (1).svg'
import iconLogo6  from '../../../assets/HowitWorks/Support Service (2).svg'
import { useState } from "react";



export default function HowItWorksContent() {
  const [workList] = useState([
    {
        img: iconLogo1,
        title: "1. Schedule a call",
    },
    {
        img: iconLogo2,
        title: "2. Get Estimation",
    },
    {
        img: iconLogo3,
        title: "3. Pay your first instalment",
    },
    {
        img: iconLogo4,
        title: "4. Visualize your progress",
    },
    {
        img: iconLogo5,
        title: "5. Go Live",
    },
    {
        img: iconLogo6,
        title: "6. Get 24*7 Support",
    },
  ])

  return (
    <div className='flex flex-wrap'>
        {workList.map((works, i) =>(
        <div key={i} className='flex items-center gap-8 w-1/2  py-6 sm:w-full'>
            <img className='bg-[#DED7F7] p-1.5 h-14 w-14 rounded-full' src={works.img} alt="" />    
            <p className='text-2xl font-medium text-[#131212] sm:text-xl customFont'>{works.title}</p>
        </div>
        ))}
    </div>
  )
}


import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

const Banner = (props: any) => {
    const { heading, contents, btn, image,clickModal } = props;

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const mediumScreen = useMediaQuery(theme.breakpoints.down("md"));
 
    const content = (
      <Grid container spacing={0} marginTop={['10px','10px','60px']} >
        <Grid item sm={6} xs={12} md={6} lg={6} order={ isSmallScreen ? 2 : 1 } >
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              flexDirection: "column",
              marginTop: "80px",
              //gap: ['10px','10px','10px'],
            }}
          >
            <Typography
              variant="h3"
              component="h3"
              sx={{
                color: "var(--neutral-01100, #FFF)",
                display: "flex",
                alignItems: "flex-start",
                fontWeight : '600',
                lineHeight : '64px',
                fontSize : '48px',
                marginBottom : '30px'
              }}
            >
              {heading ?? ''}
            </Typography>
            {contents.map((item: any) => (
              <Typography
                variant="body1"
                component={"h3"}
                sx={{
                  maxWidth : '500px',
                  color: "#ADA8C3",
                  fontFamily: "Sora",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "26px",
                  marginBottom : '30px'
                }}
              >
                <span style={{
                   fontWeight: "600",
                   color: "#FFF",
                   fontSize: "16px",
                   fontStyle: "normal",
                   lineHeight: "26px",
                   }}>
                  {item?.attributes.title}:
                </span>
                {" " + item?.attributes.description ?? ''}
              </Typography>
            ))}
            <Box
             sx={{ cursor: "pointer", }}
             >
              <img
                src={btn}
                alt="button"
                onClick={clickModal}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item sm={6} xs={12} md={6} order={ isSmallScreen ? 1 : 2 }>
          <Box>
            <img src={image} alt="image" style={{maxWidth: "100%", height: "auto"}}/>
          </Box>
        </Grid>
      </Grid>
    );
    return content;
  };

  export default Banner;
/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import "./style.css";
import GetStartedImg from "../../assets/getStarted.png";
import GetInTouch from "./GetInTouch";
import baseImg from "../../assets/home/base02.png";
import baseImg2 from "../../assets/home/base04.png";
import baseImg3 from "../../assets/home/base05.png";
import { Carousel } from "primereact/carousel";
import { SingleCard } from "../homecomponents/SingleCard";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Gradient from "../../assets/home/gradient (2).png";
import homePageApi from "../hooks/homepageapi";
import PopUp from "../ClandyWeight/Popup";
import Papercard from "../paperFeaturecard/Papercard";
import ClientSingleCard from "../clientSingleCard/ClientSingleCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EservzLogo from "../../assets/home/1.png";
import RympLogo from "../../assets/home/4.png";
import SimplySimpleLogo from "../../assets/home/3.png";
import ImsomLogo from "../../assets/home/2.png";
import IplLogo from '../../assets/home/5.png';
import { ApiUrl } from "../siteconfig/config";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const mediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [propositions, setPropositions] = useState<any>([]);
  const [whatWeDo, setWhatWeDo] = useState<any>([]);
  const [testimonials, setTestimonials] = useState<any>([]);
  const [payPerPricing, setPayPerPricing] = useState<any>([]);
  const { homeGetApi } = homePageApi();

  var settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed:3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const fetchData = async (checkVal: any) => {
      switch (checkVal) {
        case "propositions":
          const dataOne = await homeGetApi("propositions?populate=*");
          setPropositions(dataOne?.data ?? []);
          break;
        case "howwedo":
          const dataTwo = await homeGetApi("how-do-we-do-its?populate=*");
          setWhatWeDo(dataTwo?.data ?? []);
          break;
        case "testimonials":
          const dataThree = await homeGetApi("testimonials?populate=*");
          setTestimonials(dataThree?.data ?? []);
          break;
        case "pay-per-pricings":
          const dataFour = await homeGetApi("pay-per-pricings?populate=*");
          setPayPerPricing(dataFour?.data ?? []);
          break;
      }
    };
    fetchData("propositions");
    fetchData("howwedo");
    fetchData("testimonials");
    fetchData('pay-per-pricings')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // image array
  const imgArray = [
    baseImg,
    baseImg2,
    baseImg3,
    baseImg3,
    baseImg,
    baseImg2,
    baseImg3,
  ];

  const responsiveOptions = [
    {
      breakpoint: "1300px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "1100px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "600px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "480px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const [currentPage, setCurrentPage] = useState(0);
  const totalItems = testimonials?.length;
  const itemsPerPage = mediumScreen || isSmallScreen ? 1 : 2;

  const handleNext = () => {
    if (currentPage < totalItems - itemsPerPage) {
      setCurrentPage((prevPage) => Math.max(prevPage + 1));
    }
  };
  const handlePrev = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => Math.max(prevPage - 1));
    }
  };

  const [openModal, setOpenModal] = useState<boolean>(false);
  const navigate=useNavigate();
  const clickModal = () => {
    //navigate('/pricing')
    setOpenModal(!openModal);
  };

  // const payperFeatureCard = [
  //   {
  //     title: "Pay Per feature",
  //     desc: "One line Desc",
  //     feature: [
  //       { title: "Basic Feature - 2" },
  //       { title: "Intermediate Feature - 4" },
  //       { title: "Advance Feature - 2" },
  //       { title: "AI/ML/AR/VR Feature - 1" },
  //     ],
  //   },
  //   {
  //     title: "Subscription",
  //     desc: "One line Desc",
  //     feature: [
  //       { title: "Basic Feature - 2" },
  //       { title: "Intermediate Feature - 4" },
  //       { title: "Advance Feature - 2" },
  //       { title: "AI/ML/AR/VR Feature - 1" },
  //     ],
  //   },
  // ];

  const SingleCardSLiderArr = [
    EservzLogo,
    SimplySimpleLogo,
    RympLogo,
    ImsomLogo,
    IplLogo
    // CemetsLogo,
  ];
  // const SingleCardSLiderArr=[ImsomLogo]

  return (
    <>
      <Box>
        <Grid container>
          <Grid xs={12}>
            <Box className="bannerHead">
              <Typography
                variant="h3"
                style={{
                  fontWeight: "600",
                }}
                sx={{
                  fontSize: { xs: "45px", md: "60px" },
                  lineHeight: { xs: "50px", md: "72px" },
                }}
              >
                Turn Ideas into Reality <br /> with{" "}
                <span className="textTopHead">i2Pify.ai</span>
              </Typography>
              <Typography
                sx={{
                  paddingTop: "48px",
                  lineHeight: "32px",
                  fontWeight: "600",
                  fontSize: "24px",
                }}
                variant="body1"
              >
                Transparent journey by AI powered platform
              </Typography>
              <Button
                className="hoverBg"
                sx={{
                  backgroundColor: "#fff",
                  color: "#000",
                  marginTop: "32px",
                  marginBottom: "10px",
                  fontWeight: "600",
                  padding: "18px",
                }}
                onClick={(e) => clickModal()}
              >
                GET STARTED
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Box className="boxPaddingGrid">
          <Grid container>
            <Grid xs={12}>
              <Box
                style={{ position: "relative" }}
                className="sectionSecondInHome"
              >
                <Box className="imgInsideBg">
                  <img
                    src={GetStartedImg}
                    className="imgResponsive imgOutSideCss"
                  />
                </Box>
                {/* <Box className="frameFirst" data-aos="fade-up">
                  <Box
                    sx={{
                      backgroundColor: "#474060a3",
                      borderRadius: "10px",
                      padding: { xs: "4px", sm: "5px", md: "10px" },
                      textAlign: "left",
                      width: { xs: "250px", sm: "250px", md: "300px" },
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        fontSize: { xs: "10px", sm: "10px", md: "16px" },
                        color: "#fff",
                        fontWeight: "600",
                      }}
                    >
                      Suggest clothes for an office party.
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: { xs: "5px", sm: "5px", md: "10px" },
                      }}
                    >
                      <img className="UserIconCSS" src={UserIcon} />
                      <Typography
                        variant="h5"
                        sx={{
                          margin: "5px",
                          fontSize: { xs: "9px", sm: "9px", md: "12px" },
                          color: "#6C7275",
                        }}
                      >
                        1m ago
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="frameSecond" data-aos="fade-up">
                  <Box
                    sx={{
                      backgroundColor: "#474060a3",
                      borderRadius: "10px",
                      padding: { xs: "4px", sm: "5px", md: "10px" },
                      textAlign: "left",
                      width: { xs: "250px", sm: "250px", md: "320px" },
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        fontSize: { xs: "10px", sm: "10px", md: "16px" },
                        color: "#fff",
                        fontWeight: "600",
                      }}
                    >
                      Create a schedule for delivery partner.
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: { xs: "5px", sm: "5px", md: "10px" },
                      }}
                    >
                      <img className="UserIconCSS" src={UserIcon} />
                      <Typography
                        variant="h5"
                        sx={{
                          margin: "5px",
                          fontSize: { xs: "9px", sm: "9px", md: "12px" },
                          color: "#6C7275",
                        }}
                      >
                        1m ago
                      </Typography>
                    </Box>
                  </Box>
                </Box> */}
              </Box>
            </Grid>
          </Grid>

          {/* our value proposition */}

          <Grid container>
            <Box sx={{ marginTop: { xs: "20px", sm: "20px", md: "50px" } }}>
              <Box className="boxPaddingGrid">
                <Typography
                  sx={{
                    color: "#fff",
                    padding: "10px",
                    fontSize: { xs: "24px", sm: "24px", md: "48px" },
                    lineHeight: "32px",
                    fontWeight: "600",
                  }}
                  variant="h3"
                >
                  Our Value proposition
                </Typography>

                <Grid
                  container
                  sx={{
                    margin: { xs: "0px 0px", sm: "0px 0px", md: "60px 0px" },
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {propositions?.map((item: any, i: number) => (
                    <Grid xs={12} sm={6} md={6} lg={4}>
                      <Box
                        sx={{ padding: { xs: "5px", sm: "5px", md: "10px" } }}
                      >
                        <div className="inner-home-box">
                          <img src={imgArray[i]} style={{ width: "95%" }} />
                          <Typography
                            variant="h4"
                            sx={{
                              fontSize: { xs: "18px", sm: "18px", md: "24px" },
                              lineHeight: "36px",
                              fontWeight: "400",
                            }}
                            className="colorWhite inner-text-box"
                            style={{ marginBottom: "5px" }}
                          >
                            {item?.attributes?.title ?? ""}
                          </Typography>
                          <Box
                            component="img"
                            src={Gradient}
                            sx={{
                              width: { xs: "100%", md: "100%" },
                              height: "auto",
                            }}
                            alt="responsive image"
                            className="hoverImg"
                          />
                        </div>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
              {/* </Grid> */}
            </Box>
          </Grid>

          {/* our client */}

          <Grid container>
            <Box sx={{ marginTop: { xs: "20px", sm: "20px", md: "50px" } }}>
              <Box className="boxPaddingGrid">
                <Typography
                  sx={{
                    color: "#fff",
                    padding: "10px",
                    fontSize: { xs: "24px", sm: "24px", md: "48px" },
                    lineHeight: "32px",
                    fontWeight: "700",
                  }}
                  variant="h3"
                >
                  Our Clients
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Box sx={{marginTop:"30px"}}>
            <Slider {...settings}>
              {SingleCardSLiderArr.map((clientLogo: any) => (
                <div className="single-card-client">
                <ClientSingleCard image={clientLogo} />
                </div>
              ))}
            </Slider>
          </Box>

          <Grid container>
            <Box sx={{ marginTop: { xs: "20px", sm: "20px", md: "50px" } }}>
              <Box className="boxPaddingGrid">
                <Typography
                  sx={{
                    color: "#fff",
                    padding: "10px",
                    fontSize: { xs: "24px", sm: "24px", md: "48px" },
                    lineHeight: "55px",
                    fontWeight: "700",
                  }}
                  variant="h3"
                >
                  How you want to develop your tech?
                </Typography>

                <Grid
                  container
                  sx={{
                    margin: { xs: "0px 0px", sm: "0px 0px", md: "60px 0px" },
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {/* <Papercard/> */}
                  {
                    <Grid container spacing={3}>
                      {payPerPricing?.map((payPerfeature : any, index: number): any => (
                        <Grid item xs={12} sm={6} md={6}>
                          <Box
                            sx={{
                              flexGrow: 1,
                              marginY: ["10px", "10px", "80px"],
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Papercard
                              payPerfeature={{ ...payPerfeature, index }}
                              
                              clickModal={clickModal}
                            />
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  }
                </Grid>
              </Box>
            </Box>
          </Grid>

          <Box sx={{ marginTop: { xs: "20px", sm: "20px", md: "0px" } }}>
            <Box className="boxPaddingGrid">
              <Grid container sx={{ margin: { xs: "0px 0px", sm: "0px 0px" } }}>
                <Grid xs={12} sm={12} md={6}>
                  <Box sx={{ padding: { xs: "5px", sm: "5px", md: "10px" } }}>
                    <Typography
                      variant="h1"
                      sx={{ fontSize: { xs: "25px", sm: "25px", md: "48px" } }}
                      className="colorWhite"
                      style={{
                        marginBottom: "5px",
                        fontWeight: "600",
                        lineHeight: "64px",
                      }}
                    >
                      How it Works?
                    </Typography>
                  </Box>
                </Grid>
                <Grid xs={12} sm={12} md={6}>
                  <Box sx={{ padding: { xs: "5px", sm: "5px", md: "10px" } }}>
                    <p className="home-para">
                      At i2pify.ai, we leverage advanced AI to eliminate the
                      traditional hurdles of tech-product creation. From
                      inception to launch, we're by your side, ensuring a smooth
                      journey for every innovator.
                    </p>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>

          {/* How it works cards */}
          <Box sx={{ marginTop: { xs: "20px", sm: "20px", md: "50px" } }}>
            <Box className="boxPaddingGrid">
              <Grid>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: isSmallScreen ? "1fr" : mediumScreen ? "1fr 1fr" : "1fr 1fr 1fr",
                    gridGap: "40px",
                  }}
                >
                  {whatWeDo?.map((item: any, index: number) => (
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <Box
                        component="img"
                        src={`${item?.attributes?.image?.data?.attributes?.url}`}
                        sx={{
                          width: { xs: "48px", md: "48px" },
                          height: { xs: "48px", md: "48px" },
                        }}
                        alt="responsive image"
                      />
                      <Typography
                        variant="body1"
                        color="var(--neutral-03, #ADA8C3)"
                        sx={{ marginTop: { md: "15px" },
                        fontFamily : 'Sora !important',
                        fontSize : '16px !important',
                        fontWeight : '300',
                        lineHeight : '24px',
                        textOverflow: "ellipsis",
                        overflow: "hidden"
                       }}
                      >
                        {`Step ${item?.attributes?.step ?? ""}:`}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="white"
                        style={{
                          color: "#F6F3FF",
                          fontSize: "24px",
                          fontWeight: "400",
                          lineHeight: "normal",
                          letterSpacing: "-0.72px",
                          marginTop: "15px",
                        }}
                      >
                        {item?.attributes?.title ?? ""}
                      </Typography>
                    </Grid>
                  ))}
                </Box>
              </Grid>
            </Box>
          </Box>

          {/* slider  */}

          <Grid container>
            <Box
              sx={{ marginTop: { xs: "20px", sm: "20px", md: "150px" },
              marginBottom : { xs: "20px", sm: "20px", md: "80px" }
             }}
              width="100%"
            >
              <Typography
                sx={{
                  color: "var(--neutral-01100, #FFF)",
                  padding: "10px",
                  fontSize: { xs: "25px", sm: "48px", md: "48px" },
                  textAlign: "center",
                  fontWeight: "600",
                  fontStyle : 'normal',
                  lineHeight : '64px',
                  fontFamily : 'Sora !important'
                }}
                variant="h3"
              >
                Testimonial & Case Study
              </Typography>
              <div className="card-homepage">
                {isSmallScreen ? (
                  <Carousel
                    value={testimonials}
                    numVisible={2}
                    numScroll={1}
                    responsiveOptions={responsiveOptions}
                    itemTemplate={SingleCard}
                    circular
                    autoplayInterval={3000}
                  />
                ) : (
                  <Carousel
                    value={testimonials}
                    numVisible={2}
                    numScroll={1}
                    responsiveOptions={responsiveOptions}
                    itemTemplate={SingleCard}
                    page={currentPage}
                    onPageChange={(e) => setCurrentPage(e.page)}
                  />
                )}
                {!isSmallScreen && (
                  <div className="outer-button-box">
                    <button onClick={handlePrev} className="changeSlideBtn" style={{
                      marginTop : '80px',
                      gap : '32px'
                    }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                       
                      >
                        <path
                          d="M13.2413 5.70711C13.6318 5.31658 13.6318 4.68342 13.2413 4.29289C12.8508 3.90237 12.2176 3.90237 11.8271 4.29289L4.82707 11.2929C4.43655 11.6834 4.43655 12.3166 4.82707 12.7071L11.8271 19.7071C12.2176 20.0976 12.8508 20.0976 13.2413 19.7071C13.6318 19.3166 13.6318 18.6834 13.2413 18.2929L7.94839 13L19.5342 13C20.0865 13 20.5342 12.5523 20.5342 12C20.5342 11.4477 20.0865 11 19.5342 11L7.94839 11L13.2413 5.70711Z"
                          fill="#757185"
                        />
                      </svg>
                    </button>
                    <button onClick={handleNext} className="changeSlideBtn" style={{
                      marginTop : '80px',
                      gap : '32px'
                    }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                       
                      >
                        <path
                          d="M11.8271 5.70711C11.4365 5.31658 11.4365 4.68342 11.8271 4.29289C12.2176 3.90237 12.8508 3.90237 13.2413 4.29289L20.2413 11.2929C20.6318 11.6834 20.6318 12.3166 20.2413 12.7071L13.2413 19.7071C12.8508 20.0976 12.2176 20.0976 11.8271 19.7071C11.4365 19.3166 11.4365 18.6834 11.8271 18.2929L17.12 13L5.53418 13C4.9819 13 4.53418 12.5523 4.53418 12C4.53418 11.4477 4.9819 11 5.53418 11L17.12 11L11.8271 5.70711Z"
                          fill="#757185"
                        />
                      </svg>
                    </button>
                  </div>
                )}
              </div>
            </Box>
          </Grid>

          {/* <Grid
            className="aiInYourField"
            container
            sx={{ margin: { xs: "0px 0px", sm: "0px 0px" } }}
          >
            <Grid xs={12} sm={12} md={1}></Grid>
            <Grid xs={12} sm={12} md={10}>
              <Box sx={{ textAlign: "center" }}>
                <Box
                  sx={{
                    zIndex: "1",
                    position: "relative",
                    padding: {
                      xs: "50px 0px",
                      sm: "auto",
                      md: "0 50px",
                      lg: "0 160px",
                    },
                  }}
                >
                  <Typography
                    variant="h3"
                    className="colorWhite"
                    sx={{
                      fontSize: { xs: "35px", sm: "35px", md: "60px" },
                      lineHeight: { xs: "50px", sm: "50px", md: "72px" },
                    }}
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    Be the pioneers of{" "}
                    <span className="borderImageCommon borderImageHome">
                      AI
                    </span>{" "}
                    in your field.
                  </Typography>
                  <Typography
                    sx={{ margin: "20px 0" }}
                    className="colorGray"
                    variant="body1"
                  >
                    Unleash the power of AI with I2Pify.ai.
                  </Typography>
                </Box>

                <GetInTouch />
              </Box>
            </Grid>
            <Grid xs={12} sm={12} md={1}></Grid>
          </Grid> */}
        </Box>
      </Box>
      <PopUp openModal={openModal} setOpenModal={setOpenModal} />
    </>
  );
}

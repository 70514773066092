import React from "react";
import ThankYou from "../../assets/thankyou.png";
import { Box } from "@mui/material";
const ThankYouPage = () => {
  return (
    <>
      <div className="flex h-screen w-screen items-center justify-center">
        <Box>
        <img src={ThankYou} alt="not-found" style={{margin:"auto"}} />
        <h1 className="text-3xl mt-[20px] font-semibold">Thank you for chossig i2Pify</h1>
        </Box>
      </div>
    </>
  );
};

export default ThankYouPage;

import {
  Accordion,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import PayPerButton from "../../assets/home/PayPerButton.png";
import PayPerButtonTwo from "../../assets/home/PayPerButtonTwo.png";
import React from "react";
import CheckIcon from "../../assets/CheckIcon.png";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import GenericButtonSchedule from "../genericButtonSchedule/GenericButtonSchedule";

interface PayPerFeature {
  payPerfeature: {
    attributes : {
    type: string;
    description: string;
    pricing_features: {
      data : {
        attributes: {
          title : string;
        }
      }[];
    };
    index : number;
  };
}
  clickModal: () => void;
}

const useStyles = makeStyles((theme) => ({
  customAccordion: {
    // Add your custom styles for the accordion line here
    borderBottom: '1px solid rgba(255, 255, 255, 0.10)', // Example: Blue border
  },
}));

const Papercard: React.FC<PayPerFeature> = ({ payPerfeature, clickModal }) => {
  
  //console.log(payPerfeature?.attributes)
  const classes = useStyles();
  const navigate=useNavigate();

  const handleClick = () => {
     navigate('/pricing')
  }

  const colorArray = ["#FFC876", "#FF7A71"];
  const Button=["Schedule a call","get started"]
  const { type:title,description:desc}: any = payPerfeature?.attributes;
  const {index} : any = payPerfeature;

  //console.log(payPerfeature)

  const content = (
    <Grid
      item
      xs={4}
      sm={4}
      md={12}
      sx={{
        background: "1px solid var(--neutral-015, rgba(255, 255, 255, 0.10))",
        border: "1px solid var(--neutral-015, rgba(255, 255, 255, 0.10))",
        borderRadius: "32px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        overflow: "auto",
        minWidth: "300px",
        gap: "30px",
        padding: "32px",
      }}
    >
      <Box>
        <Typography
          variant="h4"
          color={colorArray[index]}
          sx={{ fontWeight: "400", fontSize: { xs: "24px", sm: "24px", md: "32px" } }}
        >
          {title && (title ?? "")}
        </Typography>
      </Box>
      <Box onClick={handleClick} sx={{cursor:"pointer"}}>
        <Typography variant="body2" color="grey" sx={{ fontWeight: "300",fontSize : '16px',lineHeight:'24px',minHeight:"100px" }}>
          {desc ?? ""}
        </Typography>

        <GenericButtonSchedule clickModal={clickModal} text={Button[index]} />
        {payPerfeature?.attributes?.pricing_features?.data?.map((item: any, index: number) => (
          <Box key={item?.id}>
            <Box>
              <Accordion sx={{ background: "none" }} expanded={false}>
                <AccordionSummary  className={classes.customAccordion} id="panel1bh-header">
                  <Typography sx={{ display: "flex" }} variant="body1">
                    <span style={{ cursor: "pointer" }}>
                      <img src={CheckIcon} alt="Not-Found" />
                    </span>
                    &nbsp;&nbsp;
                    <span className="colorWhite">{item?.attributes?.title ?? ""}</span>
                  </Typography>
                </AccordionSummary>
              </Accordion>
            </Box>
          </Box>
        ))}
      </Box>
    </Grid>
  );
  return content;
};

export default Papercard;

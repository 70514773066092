import React from "react";
import FormSubmitModal from "../formSubmitModal/FormSubmitModal";
import LeftSideLandingPara from "../leftSideLandingPagePara/LeftSideLandingPara";
import Layout from "../layout/Layout";
import SubscriptionSection from "../SubscriptionSection/SubscriptionSection";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import ClientSection from "../clientSection/ClientSection";
import CustomBanner from "../customBanner/CustomBanner";
import ProjectStartSection from "../projectStartSection/ProjectStartSection";
import TestimonialSection from "../testimonialSection/TestimonialSection";
import Innovation from "../LandingPageInnovation/Innovation";
import CustomizeSolution from "../CustomizeSolutionCard/CustomizeSolution/CustomizeSolution";
import DevVectorIcon from "../../assets/SubscriptionSection/invoice.svg";
import AiVectorIcon from "../../assets/SubscriptionSection/Ai Setting.svg";
import TransVectorIcon from "../../assets/SubscriptionSection/Transparency.svg";
import CloudVectorIcon from "../../assets/SubscriptionSection/Cloud.svg";
import CustomizeVectorIcon from "../../assets/SubscriptionSection/Customize.svg";


const LandingPageInstantStudio = () => {

  const cardData = [
    {
      title: "Zero Development Fee",
      description:
        "Start building your app without any upfront costs. We're committed to making app development accessible to everyone.",
      icon: DevVectorIcon,
    },
    {
      title: "AI-Based Development",
      description:
        "Our apps are powered by artificial intelligence, making them smarter, faster, and easier to use.",
      icon: AiVectorIcon,
    },
    {
      title: "Transparency",
      description:
        "We keep you informed at every step of the development process, ensuring clear communication and openness.",
      icon: TransVectorIcon,
    },
    {
      title: "No Cloud Fee",
      description:
        "Access the advantages of cloud technology without paying extra. Our cloud solutions come with no additional fees.",
      icon: CloudVectorIcon,
    },
    {
      title: "Flexible Customisation",
      description:
        "Unlike other platforms, we prioritise flexibility and customisation options, giving you more control over your app's design and features.",
      icon: CustomizeVectorIcon,
    },
  ];


  const style={
    OuterBox:{
      display: "flex",
      justifyContent: "space-around",
      flexWrap: "wrap",
    }as React.CSSProperties
  }
  
  return (
    <>
    <Layout>
      <Box style={style.OuterBox}>
      <LeftSideLandingPara title1={"Instant"} title2={"App Studio"} heading={"Subscription Model"} />
      {/* <div className="md:basis-full	"> */}
      <FormSubmitModal headingTitle={"Let’s make your dream website  😊"} />
      {/* </div> */}
      </Box>
      <SubscriptionSection cardData={cardData}/>
      <ClientSection/>
      <CustomizeSolution/>
      <CustomBanner/>
      <Innovation/>
      <TestimonialSection/>
      <ProjectStartSection headTitle={"Let’s make your dream website 😊"}/>
    </Layout>
    </>
  );
};

export default LandingPageInstantStudio;

import { Box, Grid, Paper, Typography, styled } from "@mui/material";
import CustomButtom from "../customButton/CustomButtom";

const CustomBanner = () => {
  return (
    <Box sx={{
      background:"#FAFCFF"}} className="py-[20px] px-[100px] sm:p-[30px]">
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={12} md={8}>
          <Typography variant="h5" fontWeight={"600"}>
            Elevate Your Business with Our Global Website Development Services
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
         <CustomButtom title={"get Started"}/>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomBanner;

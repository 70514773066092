import React from 'react'
import logo from "../../assets/LandingPageFooter/footerLogo.png"
import LandingPageFooterContent from './LandingPageFooterContent/LandingPageFooterContent'
import LandingPageFooterSocial from './LandingPageFooterSocial/LandingPageFooterSocial'


export default function LandingPageFooter() {
  return (
    <div className="bg-[#0E0C15] text-[#F1F0F1] flex justify-center py-7 sm:flex-col"> 
    <div className="w-[80%]	mx-24 max-[580px]: mx-10 sm:mx-9">
      <div className="flex items-center	gap-8">
        <img className="h-14	w-18" src={logo} alt="LOGO" />
        <p className="font-medium	text-2xl">I2Pify.ai</p>
      </div>
      <LandingPageFooterContent/>
    </div>
      <LandingPageFooterSocial/>
    </div>
  )
}


import { Box, Button, Grid, Typography } from "@mui/material";
import Donought1 from "../../assets/Pricing/active.png";
import Donought2 from "../../assets/Pricing/active2.png";
import ContactUs from "../../assets/Pricing/contactusbutton.png";
import ScheduleCallButton from "../../assets/Pricing/schedule_a_call.png";
import GetInTouch from "./GetInTouch";
import Banner from "../pricing components/Banner";
import Steps from "../pricing components/Steps";
import Plans from "../pricing components/Plans";
import PricingCards from "../pricing components/PricingCards";
import { useEffect, useState } from "react";
import PopUp from "../ClandyWeight/Popup";
import pricingPageApi from "../hooks/pricingpageapi";
import Papercard from "../paperFeaturecard/Papercard";
import GenericButtonSchedule from "../genericButtonSchedule/GenericButtonSchedule";

export default function Pricing() {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const clickModal = () => {
    setOpenModal(!openModal);
  };
  const { pricingGetApi } = pricingPageApi();

  // PayPerFeature api integration
  const [payPerPricing, setPayPerPricing] = useState<any>([]);

  useEffect(() => {
    const fetchData = async (checkVal: any) => {
      switch (checkVal) {
        case "pay-per-pricings":
          const dataPricing = await pricingGetApi("home-page-pay-per-pricings");
          setPayPerPricing(dataPricing?.data ?? []);
          break;
      }
    };
    fetchData("pay-per-pricings");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // techifyAPI
  const [techifyFeatures, setTechifyFeatures] = useState<any>([]);
  const [howitworks, setHowItWorks] = useState<any>([]);
  const [pricingCards, setPricingCards] = useState<any>([]);

  // PatchifyAPI
  const [patchifyFeatures, setPatchifyFeatures] = useState<any>([]);
  const [howitworksPatchify, setHowItWorksPatchify] = useState<any>([]);
  const [pricingCardsPatchify, setPricingCardsPatchify] = useState<any>([]);

  // Utility States
  const [priceActionTechify, setPriceActionTechify] =
    useState<string>("MONTHLY");
  const [priceActionPatchify, setPriceActionPatchify] =
    useState<string>("MONTHLY");
  const [phases, setPhase] = useState<any>([]);
  const [phasesPatchify, setPhasesPatchify] = useState<any>([]);
  const [activeTechify, setActiveTechify] = useState<boolean[]>([
    true,
    false,
    false,
  ]);
  const [activePatchify, setActivePatchify] = useState<boolean[]>([
    true,
    false,
    false,
  ]);

  // Utility functions
  const handleCardPricingTechify = (
    event: any,
    value: string,
    index: number
  ): void => {
    setPriceActionTechify(value);

    const newActive = Array(activePatchify.length).fill(false);
    newActive[index] = true;
    setActiveTechify(newActive);
  };

  const handleCardPricingPatchify = (
    event: any,
    value: string,
    index: number
  ): void => {
    setPriceActionPatchify(value);

    const newActive = Array(activePatchify.length).fill(false);
    newActive[index] = true;
    setActivePatchify(newActive);
  };

  useEffect(() => {
    const fetchData = async () => {
      //pricing-techifies
      const dataOne = await pricingGetApi("pricing-techifies");
      setTechifyFeatures(dataOne?.data ?? []);

      const dataTwo = await pricingGetApi("pricing-how-it-works");
      setHowItWorks(dataTwo?.data ?? []);

      const dataThree = await pricingGetApi("pricing-cards");
      setPricingCards(dataThree?.data ?? []);

      const dataFour = await pricingGetApi("pricing-feature-design-phases");
      const dataFive = await pricingGetApi("pricing-feature-integrations");
      const dataSix = await pricingGetApi(
        "pricing-feature-custom-integrations"
      );
      const dataSeven = await pricingGetApi("pricing-feature-weekly-demos");
      const dataEight = await pricingGetApi("pricing-feature-feedbacks");
      const dataNine = await pricingGetApi(
        "pricing-feature-dashboard-accesses"
      );
      const dataTen = await pricingGetApi("pricing-feature-flexibilities");
      const dataEleven = await pricingGetApi("pricing-feature-trial-periods");
      const dataTwelve = await pricingGetApi(
        "pricing-feature-customer-supports"
      );

      //pricing-Patchifies
      const dataPatchifyOne = await pricingGetApi("pricing-patchifies");
      setPatchifyFeatures(dataPatchifyOne?.data ?? "");

      const dataPatchifyTwo = await pricingGetApi(
        "pricing-patchify-how-it-works"
      );
      setHowItWorksPatchify(dataPatchifyTwo?.data ?? []);

      const dataPatchifyThree = await pricingGetApi("pricing-patchify-cards");
      setPricingCardsPatchify(dataPatchifyThree?.data ?? []);

      const datapatchifyFour = await pricingGetApi(
        "pricing-techify-feature-design-phases"
      );
      const datapatchifyFive = await pricingGetApi(
        "pricing-techify-feature-integrations"
      );
      const datapatchifySix = await pricingGetApi(
        "pricing-techify-feature-custom-integrations"
      );
      const datapatchifySeven = await pricingGetApi(
        "pricing-techify-feature-weekly-demos"
      );
      const datapatchifyEight = await pricingGetApi(
        "pricing-techify-feature-feedbacks"
      );
      const datapatchifyNine = await pricingGetApi(
        "pricing-techify-feature-dashboard-accesses"
      );
      const datapatchifyTen = await pricingGetApi(
        "pricing-techify-feature-flexibilities"
      );
      const datapatchifyEleven = await pricingGetApi(
        "pricing-techify-feature-trial-periods"
      );
      const datapatchifyTwelve = await pricingGetApi(
        "pricing-techify-feature-customer-supports"
      );

      const phases = dataFour?.data.reduce((result: any, item: any) => {
        const existingPhase = result.find(
          (phase: any) => phase.title === "Design Phase"
        );

        if (existingPhase) {
          existingPhase.phaseContent.push({
            id: item.id,
            attributes: item.attributes,
          });
        } else {
          result.push({
            title: "Design Phase",
            phaseContent: [
              {
                id: item.id,
                attributes: item.attributes,
              },
            ],
          });
        }
        return result;
      }, []);

      const data = [
        dataFive,
        dataSix,
        dataSeven,
        dataEight,
        dataNine,
        dataTen,
        dataEleven,
        dataTwelve,
      ];

      data?.forEach((item) => {
        if (item?.data) {
          const response = [...item.data];
          response.shift();
          phases.push({ ...item.data[0]?.attributes, phaseContent: response });
        }
      });
      setPhase(phases);

      // Patchify
      const phasesPatchify = datapatchifyFour?.data.reduce(
        (result: any, item: any) => {
          const existingPhasePatchify = result.find(
            (phase: any) => phase.title === "Design Phase"
          );

          if (existingPhasePatchify) {
            existingPhasePatchify.phaseContent.push({
              id: item.id,
              attributes: item.attributes,
            });
          } else {
            result.push({
              title: "Design Phase",
              phaseContent: [
                {
                  id: item.id,
                  attributes: item.attributes,
                },
              ],
            });
          }
          return result;
        },
        []
      );

      const dataPatchify = [
        datapatchifyFive,
        datapatchifySix,
        datapatchifySeven,
        datapatchifyEight,
        datapatchifyNine,
        datapatchifyTen,
        datapatchifyEleven,
        datapatchifyTwelve,
      ];

      dataPatchify.forEach((item) => {
        if (item?.data) {
          const response = [...item.data];
          response.shift();
          phasesPatchify.push({
            ...item.data[0]?.attributes,
            phaseContent: response,
          });
        }
      });
      setPhasesPatchify(phasesPatchify);
    };
    fetchData();
  }, []);

  const payperFeatureCard = [
    {
      title: "Pay Per feature",
      desc: "One line Description",
      feature: [
        { title: "Basic Feature - 2" },
        { title: "Intermediate Feature - 4" },
        { title: "Advance Feature - 2" },
        { title: "AI/ML/AR/VR Feature - 1" },
      ],
    },
    {
      title: "Subscription",
      desc: "One line Description",
      feature: [
        { title: "Basic Feature - 2" },
        { title: "Intermediate Feature - 4" },
        { title: "Advance Feature - 2" },
        { title: "AI/ML/AR/VR Feature - 1" },
      ],
    },
  ];

  const content = (
    <>
      <Box>
        <Box
          sx={{
            margin: {
              xs: "0 20px",
              sm: "0 20px",
              md: "0 20px",
              lg: "10px 160px",
            },
          }}
        >
          <Grid>
            <Grid xs={12} sm={12}>
              <Box className="bannerHead">
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: { xs: "30px", md: "60px" },
                    fontFamily: "Sora !important",
                    fontStyle: "normal",
                    lineHeight: "72px",
                  }}
                  variant="h1"
                >
                  i2pify Pricing
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    marginTop: "24px",
                    marginBottom: "24px",
                    color: "var(--neutral-04100, #757185)",
                    fontFamily: "Sora",
                    fontSize: { xs: "16px", md: "24px" },
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "36px",
                  }}
                  variant="h5"
                >
                  Transparent, Tailored, Trustworthy
                </Typography>
                <Typography
                  color={"var(--neutral-04100, #757185)"}
                  sx={{
                    // maxWidth: "983.485px",
                    height: "inherit",
                    textAlign: "center",
                    fontFamily: " Sora",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "36px",
                    width: { xs: "100%", sm: "100%", md: "983px" },
                  }}
                  variant="body1"
                >
                  At i2pify, we prioritise your needs and financial flexibility.
                  Our two distinct payment models cater to diverse project
                  requirements, ensuring you only pay for the value you receive.
                  Experience our transparent pricing strategy tailored to fit
                  your aspirations.
                </Typography>
              </Box>
            </Grid>
          </Grid>

          {/* Doughnout Banner Techify */}
          <Banner
            heading="Let’s Build Your Product"
            image={Donought1}
            btn={ScheduleCallButton}
            clickModal={clickModal}
            contents={[...techifyFeatures]}
          />

          <Steps
            heading="How it works"
            path="Techify"
            steps={[...howitworks]}
          />

          {/* <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            sx={{
              background: "var(--neutral-08, #0E0C15)",
              borderRadius: "12px",
              border:"1px solid var(--stroke-style-style-3, rgba(255, 255, 255, 0.15)) !important",
              fontWeight: "600",
              marginTop: "2%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              width: "400px",
              padding: "4px",
              flexShrink: "0",
            }}
          >
           <Button sx={{
            fontFamily: "Source Code Pro",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "20px", 
            letterSpacing: "0.42px",
            textTransform: "uppercase",
            color: "var(--neutral-0150, rgba(255, 255, 255, 0.50))",
            textAlign: "center",
            leadingTrim: "both",
            textEdge: "cap",
           }}>
              ONE TIME PAYMENT
           </Button>
          </Grid>
        </Box> */}

          {/* <Papercard/> */}
          {
            <Grid container spacing={3}>
              {payPerPricing?.map((payPerfeature: any, index: number): any => (
                <Grid item xs={12} sm={6} md={6}>
                  <Box
                    sx={{
                      flexGrow: 1,
                      marginY: ["10px", "10px", "80px"],
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Papercard
                      payPerfeature={{ ...payPerfeature, index }}
                      clickModal={clickModal}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          }

          {/* Price controling options please uncomment this when you in need for them */}

          {/* <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            sx={{
              background: "var(--neutral-08, #0E0C15)",
              borderRadius: "12px",
              border:
                "1px solid var(--stroke-style-style-3, rgba(255, 255, 255, 0.15)) !important",
              fontWeight: "700",
              marginTop: "2%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              width: ['auto',"400px"],
              padding: "4px",
              flexShrink: "0",
            }}
          >
            {["MONTHLY", "QUARTERLY", "ANNUALLY"].map(
              (plan: any, index: number) => (
                <Box
                  sx={{
                    display: "flex",
                    padding: ["6px 12px","12px 24px"],
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    flex: "1 0 0",
                    alignSelf: "stretch",

                    borderRadius: activeTechify[index] ? "8px" : "",
                    background: activeTechify[index]
                      ? "var(--neutral-06, #252134)"
                      : "",
                  }}
                >
                  <Button
                    sx={{
                      cursor: "pointer",
                      color: activeTechify[index]
                        ? "var(--neutral-01100, #FFF)"
                        : "var(--neutral-0150, rgba(255, 255, 255, 0.50))",
                      fontWeight: "700 !important",
                      padding: "4px",
                      fontSize: "12px",
                      fontStyle: "normal",
                      leadingTrim: "both",
                      letterSpacing: "0.42px",
                      lineHeight: "20px",
                      background: "none",
                      fontFamily : 'Source Code Pro !important',

                    }}
                    onClick={(e) => handleCardPricingTechify(e, plan, index)}
                  >
                    {plan}
                  </Button>
                </Box>
              )
            )}
          </Grid>
        </Box> */}

          {/* price card of the pricing */}
          {
            <Grid
              container
              spacing={3}
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
              }}
            >
              {pricingCards?.map((pricingPlan: any): any => (
                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <Box
                    sx={{
                      flexGrow: 1,
                      marginTop: ["10px", "10px", "80px"],
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <PricingCards
                      props={pricingPlan}
                      priceAction={priceActionTechify}
                      clickModal={clickModal}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          }

          <Typography
            sx={{
              margin:{xs:"20px",sm:"20px",md:"30px",lg:"50px auto"},
              marginBottom: "20px",
              color: "var(--neutral-01100, #FFF)",
              textAlign: "center",
              fontSize: {xs:"24px",sm:"24px",md:"30px",lg:"48px"},
              fontStyle: "normal",
              fontWeight: "600",
              width:{lg:"50%"},
            }}
            variant="h2"
          >
           Required Suggestion in selecting a plan?
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              height: "auto",
              cursor: "pointer",
              margin: "50px 0",
            }}
          >
            <button
              className="navbar-btn"
              style={{ width: "300px", textTransform: "uppercase" }}
              onClick={clickModal}
            >
              Schedule a Call
            </button>
            {/* <GenericButtonSchedule
              clickModal={clickModal}
              text={"Schedule a Call"}
            /> */}
          </Box>

          {/* <Box
           sx={{
             display: "flex",
             justifyContent: "center",
             height: "auto",
             cursor: "pointer",
             margin:"100px 0"
             }}
             >
           <GenericButtonSchedule clickModal={clickModal} text={'Schedule a Call'} />
          </Box> */}
          {/* <Box>


<GenericButtonSchedule clickModal={clickModal} text={"contact sales"} />
        </Box> */}

          {/* Compare you plans  */}
          {/* <Plans phases={phases} /> */}
          {/* Doughnout Banner Patchify */}

          {/* <Banner
          heading="Patchify"
          image={Donought2}
          btn={ScheduleCallButton}
          clickModal={clickModal}
          contents={[...patchifyFeatures]}
        /> */}

          {/* How it works */}
          {/* <Steps
          heading="How it works"
          path="Patchify"
          steps={[...howitworksPatchify]}
        /> */}

          {/* <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid
            container
            sx={{
              background: "var(--neutral-08, #0E0C15)",
              borderRadius: "12px",
              border:
                "1px solid var(--stroke-style-style-3, rgba(255, 255, 255, 0.15)) !important",
              fontWeight: "600",
              // marginTop: "2%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              width: "400px",
              padding: "4px",
              flexShrink: "0",
              marginTop: { xs: "40px", sm: "40px", md: "auto" },
            }}
          >
            {["MONTHLY", "QUARTERLY", "ANNUALLY"].map(
              (plan: any, index: number) => (
                <Box
                  sx={{
                    display: "flex",
                    padding: "12px 24px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    flex: "1 0 0",
                    alignSelf: "stretch",
                    borderRadius: activePatchify[index] ? "8px" : "",
                    background: activePatchify[index]
                      ? "var(--neutral-06, #252134)"
                      : "",
                  }}
                >
                  <Button
                    sx={{
                      cursor: "pointer",
                      color: activePatchify[index]
                        ? "var(--neutral-01100, #FFF)"
                        : "var(--neutral-0150, rgba(255, 255, 255, 0.50))",
                      fontWeight: "600",
                      padding: "4px",
                      fontSize: "12px",
                      fontFamily: "Source Code Pro !important",
                      fontStyle: "normal",
                      leadingTrim: "both",
                      letterSpacing: "0.42px",
                      lineHeight: "20px",
                      background: "none",
                    }}
                    onClick={(e) => handleCardPricingPatchify(e, plan, index)}
                  >
                    {plan}
                  </Button>
                </Box>
              )
            )}
          </Grid>
        </Box> */}

          {/* price card of the pricing */}
          {/* {
          <Grid container spacing={3}>
            {pricingCardsPatchify.map((pricingPlan: any, index: number) => (
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    flexGrow: 0,
                    marginY: ["10px", "10px", "80px"],
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <PricingCards
                    props={pricingPlan}
                    priceAction={priceActionTechify}
                    index={index}
                    clickModal={clickModal}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        } */}

          {/* <Plans phases={phasesPatchify} /> */}

          <Box marginBottom={5}>
            <Grid container spacing={0} alignItems="flex-end">
              <Grid item xs={12} sm={6} md={6}>
                <Box sx={{ p: 2, color: "white", textAlign: "start" }}>
                  <Typography
                    variant="h3"
                    sx={{
                      maxWidth: "500px",
                      fontWeight: "600",
                      fontSize: { xs: "35px", sm: "35px", md: "50px" },
                    }}
                  >
                    Your Vision, Our Commitment
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} marginTop={8}>
                <Box sx={{ p: 2, color: "white", textAlign: "start" }}>
                  <Typography
                    variant="body1"
                    sx={{
                      maxWidth: "500px",
                      color: "#ADA8C3",
                      lineHeight: "24px",
                      fontWeight: "300",
                    }}
                  >
                    At i2pify, we are dedicated to providing you with
                    transparent and trustworthy payment models that align with
                    your goals. Whether you're looking for specific feature
                    implementation or ongoing collaboration, we’re here to bring
                    your vision to life seamlessly.
                  </Typography>
                  {/* <Box sx={{ mt: 4, cursor: "pointer" }}>
                  <img src={ContactUs} alt="button" />
                </Box> */}
                </Box>
              </Grid>
            </Grid>
          </Box>
          {/* Get in Touch with the custom message over the top*/}
          {/* <Grid
          className="aiInYourField"
          container
          sx={{ margin: { xs: "0px 0px", sm: "0px 0px", md: "60px 0px" } }}
        >
          <Grid xs={12} sm={12} md={1}></Grid>
          <Grid xs={12} sm={12} md={10}>
            <Box sx={{ textAlign: "center" }}>
              <Box
                sx={{
                  zIndex: "1",
                  position: "relative",
                  padding: { xs: "50px 0px", sm: "auto", md: "0 90px" },
                }}
              >
                <Typography
                  sx={{
                    padding: { xs: "50px 0px", sm: "auto" },
                    fontWeight: "600",
                    fontSize: { xs: "35px", sm: "35px" },
                  }}
                  variant="h3"
                  className="colorWhite"
                >
                  We offer demo or consultation for{" "}
                  <span className="borderImage2">Potential</span> client
                </Typography>
                <Typography
                  sx={{ margin: "20px 0", fontWeight: "600" }}
                  className="colorGray"
                  variant="body1"
                >
                  Unleash the power of AI within I2Pify.ai. Upgrade your
                  productivity <br /> with I2Pify.ai, the open AI chat app.
                </Typography>
              </Box>
              <GetInTouch />
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={1}></Grid>
        </Grid> */}
        </Box>
      </Box>

      <PopUp openModal={openModal} setOpenModal={setOpenModal} />
    </>
  );
  return content;
}

import { Box, Typography } from "@mui/material";
import React from "react";

const TestimonialSingleSlider = ({ sliderData, index, activeIndex }: any) => {
  const { desc, name, position } = sliderData;
//   const style={
//     lowerSectionPara:{
//         display:"flex",
//         marginTop:"30px"
//     } as React.CSSProperties
//   }
  return (
    <>
      {activeIndex === index && (
        <Box className="basis-6/12 sm:basis-full mt-6 ">
          <Typography className="customFont">{desc}</Typography>
          <Box>
            <Typography className="customFont" style={{fontWeight:"600"}}>{name}</Typography>
            <span className="customFont">{position}</span>
          </Box>
        </Box>
      )}
    </>
  );
};

export default TestimonialSingleSlider;

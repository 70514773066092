import {
  Alert,
  Box,
  Grid,
  Menu,
  MenuItem,
  Snackbar,
  SnackbarOrigin,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { ApiUrl } from "../siteconfig/config";
import { useNavigate } from "react-router-dom";

const FormSubmitModal = ({headingTitle}:any) => {
  const formDataArr = [
    {
      label: "Your Name",
      placeHolder: "Enter Name",
      id: "name",
      type: "text",
    },
    {
      label: "Your Email",
      placeHolder: "Enter Email",
      id: "email",
      type: "email",
    },
    {
      label: "Phone Number",
      placeHolder: "Enter Phone Number",
      id: "phone",
      type: "number",
    },
    {
      label: "Select a Service",
      placeHolder: "Select Service",
      id: "service",
      type: "dropdown",
    },
    {
      label: "Prospective kick off Date",
      placeHolder: "Select Prospective",
      id: "prospective",
      type: "dropdown",
    },
    {
      label: "Requirement",
      placeHolder: "Enter Requirement",
      id: "Brief",
      type: "text",
    },
  ];

  const menuItemList = [
    "Ecommerce",
    "Fitness",
    "Health",
    "Edutech",
    "Agritech",
    "Fintech",
    "IOT",
    "Logistics",
    "Others",
  ];
  const menuItemSecondList = ["1 Month", "6 Month", "8 Month", "1 Year"];

  const [formInputData, setFormInputData] = useState<any>({
    name: "",
    email: "",
    phone: "",
    service: "",
    Brief: "",
    prospective: "",
  });

  const [errors, setErrors] = useState<any>({
    name: "",
    email: "",
    phone: "",
    service: "",
    Brief: "",
    prospective: "",
  });

  const [state, setState] = useState<any>({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
  const [msgInfo, setMsgInfo] = useState<
    "success" | "info" | "warning" | "error"
  >("error");
  const [message, setMessage] = useState<string>("");

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);

  const openMenu = Boolean(anchorEl);
  const openMenu2 = Boolean(anchorEl2);
  const navigate = useNavigate()


  const handleClickOption = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickOption2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleCloseOption = () => {
    setAnchorEl(null);
  };
  const handleCloseOption2 = () => {
    setAnchorEl2(null);
  };

  const handleMenuItemClick = (value: string, id: string) => {
    setAnchorEl(null);
    setAnchorEl2(null);
    setFormInputData({
      ...formInputData,
      [id]: value,
    });
    setErrors({
      ...errors,
      [id]: "",
    });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const handleClick = (newState: SnackbarOrigin) => {
    setState({ ...newState, open: true });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const requiredFields = ["email", "name", "phone", "service","prospective", "Brief"];
    const newErrors = { ...errors };
    let valid = true;

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formInputData.email || !emailRegex.test(formInputData.email)) {
      newErrors.email = "Invalid email address";
      valid = false;
    }

    // Phone number validation
    const phoneRegex = /^\d{10}$/;
    if (!formInputData.phone || !phoneRegex.test(formInputData.phone)) {
      newErrors.phone = "Invalid  number";
      valid = false;
    }

    requiredFields.forEach((field) => {
      if (!formInputData[field]) {
        newErrors[field] = `${field} is required`;
        valid = false;
      }
    });

    if (valid) {
      const data = { ...formInputData };
      try {
        const response = await axios.post(`${ApiUrl}/api/payperfeatures`, {
          data,
        });
        setMessage("Message Send!");
        navigate('/thankyou')
        return response.data;
      } catch (error) {
        console.error("Error occurred:", error);
        setMsgInfo("error");
        setMessage("Something Went Wrong");
        handleClick({ vertical: "top", horizontal: "right" });
      }
    } else {
      setErrors(newErrors);
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    let errorMessage = "";
    // Perform validation based on the field name
    switch (name) {
      case "email":
        // Regular expression for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        errorMessage = !value
          ? "email is required"
          : !emailRegex.test(value)
          ? "Invalid email address"
          : "";
        break;
      case "phone":
        // Regular expression for phone number validation
        const phoneRegex = /^\d{10}$/;
        errorMessage = !value
          ? "number is required"
          : !phoneRegex.test(value)
          ? "Invalid  number"
          : "";
        break;
      default:
        errorMessage = !value ? `${name} is required` : "";
    }
    setErrors({
      ...errors,
      [name]: errorMessage,
    });
    setFormInputData({
      ...formInputData,
      [name]: value,
    });
  };


  const style = {
    formSubmitBox: {
      background: "#fff",
      boxShadow: "1px 1px 12px #00000012",
      padding: "40px",
      marginTop: "120px",
      width: "100%",
      // width: isSmallScreen ? "100px" : "",
      // marginTop: "150px",
    } as React.CSSProperties,
    boxHeading: {
      fontSize: "1.6rem",
      fontWeight:"500"
    } as React.CSSProperties,
    singleInputBox: {
      marginTop: "20px",
    } as React.CSSProperties,
    inputLabel: {
      fontSize: "18px",
      fontWeight: "600",
      display: "block",
    } as React.CSSProperties,
    submitBtn: {
      background: "#5938DD",
      width: "100%",
      padding: "16px 20px",
      outline: "none",
      border: "none",
      color: "#fff",
      fontWeight: "600",
      marginTop: "30px",
      borderRadius: "12px",
    } as React.CSSProperties,
    customInputField: {
      width: "100%",
      padding: "15px 10px",
      border: "1px solid #EDEDED",
      borderRadius: "12px",
      margin: "8px 0",
    } as React.CSSProperties,
  };


  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        key={vertical + horizontal}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert
          onClose={handleClose}
          severity={msgInfo}
          sx={{ width: "100%", fontWeight: "600" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Box
        // style={style.formSubmitBox}
        className="basis-1/2 mt-[150px] md:basis-full sm:basis-full w-full mt-[20px]"
      >
        <Box style={style.formSubmitBox}>
        <Typography style={style.boxHeading} className="customFont">
          {headingTitle}
        </Typography>
        <form>
        <Grid container spacing={2}>
          {formDataArr?.map((singleFormInput: any) => (
          <Grid item xs={12} md={6} lg={6}>
            <Box style={style.singleInputBox}>
              <label style={style.inputLabel} className="customFont">{singleFormInput?.label}</label>
              {singleFormInput?.id === "prospective" ||
              singleFormInput?.id === "service" ? (
                <input
                  style={{
                    ...style.customInputField,
                    cursor:
                      singleFormInput?.type === "dropdown" ? "pointer" : "",
                  }}
                  type={singleFormInput?.type}
                  placeholder={singleFormInput?.placeHolder}
                  value={formInputData[singleFormInput?.id]}
                  name={singleFormInput?.id}
                  onChange={(e) =>
                    singleFormInput?.type !== "dropdown" && handleChange(e)
                  }
                  onClick={(e: any) =>
                    singleFormInput?.id === "prospective"
                      ? handleClickOption(e)
                      : handleClickOption2(e)
                  }
                />
              ) : (
                <input
                  style={{
                    ...style.customInputField,
                    cursor:
                      singleFormInput?.type === "dropdown" ? "pointer" : "",
                  }}
                  type={singleFormInput?.type}
                  placeholder={singleFormInput?.placeHolder}
                  value={formInputData[singleFormInput?.id]}
                  name={singleFormInput?.id}
                  onChange={(e) =>
                    singleFormInput?.type !== "dropdown" && handleChange(e)
                  }
                />
              )}

              {singleFormInput?.id === "prospective" && (
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleCloseOption}
                  style={{ width: "600px !important" }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {menuItemSecondList?.map((menuItem, index): any => (
                    <MenuItem
                      key={index}
                      style={{ width: "480px" }}
                      onClick={() =>
                        handleMenuItemClick(menuItem, singleFormInput?.id)
                      }
                    >
                      {menuItem}
                    </MenuItem>
                  ))}
                </Menu>
              )}

              {singleFormInput?.id === "service" && (
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl2}
                  open={openMenu2}
                  onClose={handleCloseOption2}
                  style={{ width: "600px !important" }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {menuItemList?.map((menuItem, index): any => (
                    <MenuItem
                      key={index}
                      style={{ width: "480px"  }}
                      onClick={() =>
                        handleMenuItemClick(menuItem, singleFormInput?.id)
                      }
                    >
                      {menuItem}
                    </MenuItem>
                  ))}
                </Menu>
              )}

              {errors?.[singleFormInput?.id] && (
                <span style={{ color: "red", marginTop: "10px" }}>
                  {errors?.[singleFormInput?.id]}
                </span>
              )}
            </Box>
            </Grid>
          ))}
          </Grid>
          <button style={style.submitBtn} onClick={handleSubmit} className="customFont">
            Submit
          </button>
        </form>
        </Box>
      </Box>
    </>
  );
};

export default FormSubmitModal;

import React from "react";
import Layout from "../layout/Layout";
import { Box } from "@mui/material";
import LeftSideLandingPara from "../leftSideLandingPagePara/LeftSideLandingPara";
import FormSubmitModal from "../formSubmitModal/FormSubmitModal";
import SubscriptionSection from "../SubscriptionSection/SubscriptionSection";
import ClientSection from "../clientSection/ClientSection";
import CustomBanner from "../customBanner/CustomBanner";
import TestimonialSection from "../testimonialSection/TestimonialSection";
import ProjectStartSection from "../projectStartSection/ProjectStartSection";
import Innovation from "../LandingPageInnovation/Innovation";
import HowItWorks from "../LandingHowitWorks/HowItWorks";
import DevVectorIcon from "../../assets/SubscriptionSection/invoice.svg";
import AiVectorIcon from "../../assets/SubscriptionSection/Ai Setting.svg";
import TransVectorIcon from "../../assets/SubscriptionSection/Transparency.svg";
import CloudVectorIcon from "../../assets/SubscriptionSection/Cloud.svg";
import CustomizeVectorIcon from "../../assets/SubscriptionSection/Customize.svg";
import CustomizeSolution from "../CustomizeSolutionCard/CustomizeSolution/CustomizeSolution";

const LandingPagePayPerFeature = () => {
  const style = {
    OuterBox: {
      display: "flex",
      justifyContent: "space-around",
      flexWrap: "wrap",
    } as React.CSSProperties,
  };

  const cardData = [
    {
      title: "Upfront estimation",
      description:
        "Gain confidence with upfront estimation, ensuring transparency and predictability in project timelines.",
      icon: DevVectorIcon,
    },
    {
      title: "No hidden cost",
      description:
        "Enjoy cost clarity with no hidden fees, ensuring a transparent and trustworthy partnership.        ",
      icon: AiVectorIcon,
    },
    {
      title: "Automated software delivery",
      description:
        "Experience seamless Automated Software Delivery, streamlining processes and minimizing manual interventions.",
      icon: TransVectorIcon,
    },
    {
      title: "weekly demo",
      description:
        "Stay in the loop with Weekly Demos, ensuring constant communication and alignment with your project goals.",
      icon: CloudVectorIcon,
    },
    {
      title: "Delivery process Visualisation",
      description:
        "Visualize your project's delivery process effortlessly, akin to the user-friendly interfaces of Swiggy and Zomato.",
      icon: CustomizeVectorIcon,
    },
  ];

  return (
    <Layout>
      <Box style={style.OuterBox}>
        <LeftSideLandingPara title1={"World class"} title2={"Mobile App and Web Development"} headingArr={["Ai Driven software development","Automated delivery","Transparency","Flexible payment plan","Easy customization","PayPer Feature"]} />
        <FormSubmitModal
          headingTitle={
            "Your gateway to superior website and app development solutions  😊"
          }
        />
      </Box>
      <SubscriptionSection
        cardData={cardData}
      />
      <ClientSection />
      <CustomizeSolution/>
      <CustomBanner />
      <HowItWorks />
      <Innovation />
      <TestimonialSection />
      <ProjectStartSection headTitle={"Your gateway to superior website and app development solutions"} />
    </Layout>
  );
};

export default LandingPagePayPerFeature;

import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import Arrow from "../../assets/Arroow.png";
import ArrowOne from "../../assets/ArrowOne.png";
import CircleIcon from "../../assets/CircleIcon.png";
import "../../custom-font.css";

const LeftSideLandingPara = ({ title1, title2,heading,headingArr }: any) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const style = {
    heading: {
      fontSize: isSmallScreen ? "2.5rem" : "4rem",
      fontWeight: "600",
      color: "#000",
    } as React.CSSProperties,
    headingTwo: {
      fontSize: isSmallScreen ? "2.5rem" : "4rem",
      fontWeight: "600",
      color: "#5938DD",
    } as React.CSSProperties,
    subscriptionBtn: {
      background: "#FF760A",
      color: "#fff",
      fontWeight: "600",
      borderRadius: "100px",
      marginLeft:"20px"
    } as React.CSSProperties,
    chips:{
      flexBasis:isSmallScreen ? '100%' :"30%",
      fontSize:"12px",
      background:"rgb(89, 56, 221)",
      textAlign:"center",
      padding:"5px",
      borderRadius:"20px",
      color:"#fff",
      margin:"10px",
      fontWeight:"500",
      minHeight:"40px",
      display:"flex",
      justifyContent:"center",
      alignItems:"center"
    } as React.CSSProperties
  };
  return (
    <Box className="basis-1/2 md:basis-full 	sm:basis-full w-full">
      <Box className="mt-[120px] flex items-center">
        <Box>
          <h1 style={style.heading} className="customFont">
            {title1}
          </h1>
          <h1 style={style.headingTwo} className="customFont">
            {title2}
          </h1>
        </Box>
        {/* <img src={Arrow} alt="not-found" className="ml-[30px] mr-[20px] sm:w-[100px]" /> */}
      </Box>
      <ul
        style={{ listStyle: "disc" }}
        className="mt-[40px] w-[450px] sm:w-full"
      >
        <li className="customFont">
          We're changing the game in app development with our{" "}
          <span style={{ fontWeight: "600" }}> AI-based technology,</span>{" "}
          transparent processes, and flexible solutions.{" "}
        </li>
        <li className="customFont">
          Join us for seamless, affordable, and innovative app creation.
        </li>
      </ul>
      <img
        src={ArrowOne}
        alt="Not-Found"
        style={{ marginTop: "20px" }}
        className="sm:w-full"
      />
   
      <div className="flex justify-end my-[50px] mr-[30px] flex-wrap sm:justify-center">
      <div>
      <Button style={style.subscriptionBtn} className="customFont">
        Our Key Offering
      </Button>
      <div className="flex mt-[20px] flex-wrap sm:w-full">
      {
        headingArr?.map((item:any)=>(
          <Typography style={style.chips}>
          {item}
        </Typography>
        ))
      }
      </div>
      <Typography variant="h4" sx={{ marginTop: "20px", fontWeight: "600" }}>
       {heading}
      </Typography>
      {
        !headingArr && <p className="w-1/2	font-normal	mt-7 sm:w-[100%] customFont">
        Easily manage your app with our subscription-based model, providing you
        with continuous support and updates for seamless operation.
      </p>
      }
      
      </div>
        <img src={CircleIcon} alt="Not-Found" style={{ marginTop: "20px" }} />
      </div>
     
    </Box>
  );
};

export default LeftSideLandingPara;

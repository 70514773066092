import React, { useEffect, useState } from "react";
import { Box,Button as MuiButton, IconButton, Toolbar,
    ListItem,
    ListItemButton,
    ListItemIcon,
    List,
    ListItemText,
    Divider,
    Button,

 } from "@mui/material";
 import InboxIcon from '@mui/icons-material/MoveToInbox';
 import MailIcon from '@mui/icons-material/Mail';
import Grid from "@mui/material/Grid";
import MenuIcon from '@mui/icons-material/Menu';
import "./style.css"
import Drawer from '@mui/material/Drawer';
import { Link, NavLink, useLocation } from "react-router-dom";
import Logo from "../../assets/Logo.png"
import Logo2 from "../../assets/footerlogo.png"
import PopUp from "../ClandyWeight/Popup";

interface MenuOptionModel {
    label: string,
    link: string,
    type: string,
    onClick?:()=>void;
  }
  const drawerWidth = 240;
function Navbar() {

  

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const location = useLocation();
    const menuOptions:MenuOptionModel[] = [
        {
          label: "Home",
          link: "/",
          type: "text",
        },
        {
          label: "About Us",
          link: "/aboutUs",
          type: "text",
        },
        {
          label: "Features",
          link: "/feature",
          type: "text",
        },
        {
          label: "Pricing",
          link: "/pricing",
          type: "text",
        },

        {
          label: "Contact Us",
          link: "/contactUs",
          type: "text",
        },
        {
          label: "SCHEDULE A CALL",
          link: "",
          type: "button",
        }
      ];
      
      const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
      };

      const [openModal,setOpenModal]=useState<boolean>(false)
      const clickModal=()=>{
        setOpenModal(!openModal)
      }

      const drawer = (
        <div>
            <div style={{backgroundColor:"#0E0C15", padding:"4px"}}>
            <Link to="/" style={{padding:"10px"}}>
                    <img src={Logo2} style={{width:"150px"}} />
                  </Link>
            </div>
           
          <Divider style={{border:"1px solid rgb(172 167 167 / 7%)"}} />
          <List>
              <ListItem disablePadding>
                <ListItemButton>
                  {/* <ListItemIcon>
                  { <InboxIcon />}
                  </ListItemIcon> */}
                  <NavLink style={{textDecoration:"none", color:"#fff"}} to="/" onClick={handleDrawerToggle}>Home</NavLink>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                <NavLink style={{textDecoration:"none", color:"#fff"}} to="/aboutUs" onClick={handleDrawerToggle}>About Us</NavLink>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                <NavLink style={{textDecoration:"none", color:"#fff"}} to="/feature" onClick={handleDrawerToggle}>Feature</NavLink>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                <NavLink style={{textDecoration:"none", color:"#fff"}} to="/pricing" onClick={handleDrawerToggle}>Pricing</NavLink>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                <NavLink style={{textDecoration:"none", color:"#fff"}} to="/contactUs" onClick={handleDrawerToggle}>Contact Us</NavLink>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
              <Button
                  className="hoverBg"
                  sx={{
                    backgroundColor: "#fff",
                    color: "#000",
                    marginTop: "32px",
                    marginBottom: "10px",
                    fontWeight:"600",
                    padding:"18px",
                  }}
                  onClick={e => clickModal()}
                >
                  GET STARTED
                </Button>
                {/* <ListItemButton>
                <NavLink style={{textDecoration:"none", color:"#fff"}} to="/contactUs">Contact Us</NavLink>
                </ListItemButton> */}
              </ListItem>
          </List>
          <Divider style={{border:"1px solid rgb(172 167 167 / 7%)"}} />
        </div>
      );
    
  return (
        <>
        <Box className="home_container">
            <Box
              sx={{ flexGrow: 1 }}
              className="web_header_grid"
            >
              <Grid
                container
                spacing={2}
                sx={{
                  paddingLeft: "5%",
                  paddingRight: "5%",
                  height: { xs: "80px", md: "120px" }  
                }}
              >
                <Grid
                  item
                  xs={11} sm={11} md={3}
                  className="web_grid_prop logo_grid"
                >
                  <Link to="/" style={{padding:"10px"}}>
                    <img src={Logo} style={{width:"52px"}} />
                  </Link>
                </Grid>
                <Grid 
                item
                sm={1}
                xs={1}
                md={6}
                display={{ xs: "flex", sm: "flex", md: "none" }}
                >
                    <Box>
                    <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'flex' }, color:"#fff" }}
              >
                <MenuIcon />
              </IconButton>
                    </Box>
                </Grid>
                <Grid
                  item
                  sm={1}
                  xs={1}
                  md={9}
                  display={{ xs: "none", sm: "none", md: "flex" }}
                  className="web_grid_prop options_grid"
                >
                  {menuOptions.map((option:any, i) => {
                    return (
                      option.type === "button" ? 
                      <button
                      className="navbar-btn"
                      onClick={e => clickModal()}
                    >
                      {option.label}</button> :
                        <Link to={option.link} className="nav_link">
              <h4
                className={
                  location.pathname === option.link ? "nav_text active" : "nav_text"
                }
                
              >
                {option.label}
              </h4>
            </Link>
                        
                    );
                  })}
                </Grid>
              </Grid>
            </Box>
              <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', sm: 'block', md:'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, background:"#0e0c15" },
                
              }}
            >
              {drawer}
            </Drawer>
          </Box>
        <PopUp openModal={openModal} setOpenModal={setOpenModal} />
        </>

  )
}


export default Navbar

import { Box } from "@mui/material";

const ClientSingleCard = ({image}:any) => {
  return (
    <>
      {/* <div className="client_single_card"> */}
        <Box
          component="img"
          src={image}
          
          // style={{marginLeft:"10px"}}
          sx={{
            width:"auto",
            //  width: { xs: "270px", md: "270px" },
            // height: { xs: "136px", md: "136px" },
          //   margin:{md:"10px",lg:"10px"}
          }}
          alt="responsive image"
        />
      {/* </div> */}
    </>
  );
};

export default ClientSingleCard;

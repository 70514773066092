import { PopupModal } from "react-calendly";

const PopUp = ({openModal,setOpenModal}:any) => {
  return (
    <div>
      <PopupModal
        url="https://calendly.com/i2pify"
        rootElement={document.getElementById("root") as HTMLElement }
        open={openModal}
        onModalClose={()=>setOpenModal(!openModal)} 
      />
      
    </div>
  );
};

export default PopUp;
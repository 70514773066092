import React from 'react'
import HowItWorksContent from './HowItWorksContent/HowItWorksContent'


export default function HowItWorks() {    
  return (
    <div className="py-7 mx-24 sm:mx-6">    
      <p className='text-5xl	font-medium	text-[#000000] sm:text-3xl customFont'>How it works?</p>
      <p className='py-5 text-2xl	text-[#4E4E50] sm:text-lg customFont'>Experience the benefits of our innovative pay-per-feature model.</p>
      <HowItWorksContent/>
    </div>
  )
}


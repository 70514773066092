import React from 'react'
import logo from "../../assets/LandingPageInnovation/Group 733.svg"
import InnovationContent from './InnovationConnect/InnovationContent'

export default function Innovation() {
  return (
    <div className="flex items-center	justify-between lg:mx-24 my-16  md:flex-col mx:2 my:2 sm:flex-col mx-1 my:2">
        <InnovationContent/>
        <div className="w-1/4 md:w-2/4 mt-10 flex justify-center">
            <img className="size-40" src={logo} alt="Not-Found" />
        </div>
    </div>
  )
}

